<template>
  <tr style="border-bottom: 1px solid #333;">
    <td>
      <h4 class="form-ticket-kind-name" style="font-size: 16px; font-weight: bold; margin-bottom: 2px;">{{ticketKind.form_name}}</h4>
      <p class="form-ticket-kind-description" style="font-size: 13px;" >{{ ticketKind.description }}</p>
    </td>          
    <td style="text-align: right;">
      <del v-if="ticketKind.original_price" style="font-size: 14px;" class="form-ticket-kind-original old-price">{{ ticketKind.original_price_string }} </del>
    </td>
    <td style="text-align: right;">
      <span class="form-ticket-kind-price ticket-price" style="font-size: 22px; font-weight: 700">{{ ticketKind.price_string }}</span>
    </td>
    <td style="text-align: right; max-width: 100px; vertical-align: middle;">
      <select @change="update" v-model="selectedValue" class="form-control custom-select" style="min-width: 54px; max-width: 100px; margin-left: auto;">
        <option :value="option" v-for="(option, key) in selectOptions" :key="key">{{ option }}</option>
      </select>
    </td>
  </tr>
</template>

<script>
export default {

  name: 'GlobalTicketConsumerTicketSelector',
  props: ['ticketKind'],
  data () {
    return {
      selectedValue: 0
    }
  },
  methods: {
    update() {
      console.log('updating selection')
      this.$emit('change', this.ticketKind.id, this.selectedValue)
    }
  },
  computed: {
    hasOriginalPrice() {
      return this.ticketKind.original_price && this.ticketKind.original_price != this.ticketKind.price
    },
    selectOptions() {
      // return [4, 5, 6, 7, 8, 9, 10]
      let nums = Array(100)
        .fill(1)
        .map((x, y) => x + y)
      nums.unshift(0)
      return nums
    }
  }
}
</script>

<style lang="css" scoped>
</style>