<template>
  <a :href="product.url" class="">
    <div class="card rounded">
      <img class="card-img-top" height="200px" :src="product.image" alt="Card image cap" style="object-fit: cover;" :style="`background-color: ${product.color}`">
      <div class="card-body">
        <h5 class="card-title">{{ product.title }}</h5>
        <p class="card-text">
          <ul>
            <li v-for="(item, key) in product.items" :key="key" class="items">
              <i class='fa fa-check-circle text-success mr-1'/><span v-html="filter_link_to_home(item)"></span>
            </li>
          </ul>
        </p>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary btn-block">{{product.button_text}}</button>
      </div>
    </div>
  </a>
</template>


<script>
	export default {
		name: "Product",
		props: ['product'],

    methods: {
      filter_link_to_home(usp){
				if(usp.includes("DagjeWeg.NL Tickets")){
					return usp.replace("DagjeWeg.NL Tickets", "<a class='card-link' href='/'>DagjeWeg.NL Tickets</a>")
				}else{
					return usp
				}
			}
    }
	};
</script>

<style scoped>

</style>