<template>
  <div>

    <div v-if="currentStep == 'selectTickets'">
      <div class="row">
        <div class="col-md-12">
          <table class="table">
            <tr class="ticket-select" style="cursor: pointer;" :key="`tk-${ticketKind.id}`" v-for="ticketKind in ticketKinds" @click="selectTicketKind(ticketKind)">
              <td style="padding-right: 10px;">
                <div class="form-check" v-if="ticketKinds.length > 1">
                  <input class="form-check-input" type="radio" :checked="selectedTicketKind == ticketKind">
                </div>
              </td>
              <th style="padding-right: 10px;">
                {{ ticketKind.form_name }}
                <span style="font-weight: normal; display: block;" v-if="ticketKind.description.length">{{ ticketKind.description }}</span>
              </th>
              <td style="">
                <small style="display: block; width: 100%; margin-bottom: -10px;">Vanaf</small>
                <span style="font-size: 2em; font-weight: 700;">{{ ticketKind.price_string }}</span>
              </td>
            </tr>
          </table>    
        </div>
      </div>
      
    </div>
    <div v-if="loadingDates" style="text-align: center; margin-top: 30px; margin-bottom: 30px;">
      <i class='fa fa-spinner fa-spin'></i> {{ translations.fetching_dates }}
    </div>
    <div v-if="selectedTicketKind">
      <date-picker v-if="dates.length" v-model="date" :config="options" @dp-change="dateChanged"></date-picker>

      <div v-if="loadingTimes" style="text-align: center; margin-top: 30px; margin-bottom: 30px;" >
        <i class='fa fa-spinner fa-spin'></i> {{ translations.fetching_times }}
      </div>
      
      <div class="row" style="margin-top: 10px;" v-if="times.length">
        <div class="col" style="padding: 0px 15px;">
          <center><h4>{{ translations.select_a_time }}</h4></center>
          <select v-model="selectedTime" class="form-control" @change="chooseTime">
            <option :value="null">{{ translations.select_a_time }}</option>
            <option :value="time" :key="`time-${i}`" v-for="(time, i) in times" :disabled="time.available == 0">
              {{ time.time}} ({{ time.available}} {{ translations.available }})
            </option>
          </select>
        </div>
      </div>
    </div>
    <PrioTicketTicketSelector @change="changeTicketSelection" :tickets="prioticketTickets" :max-to-select="selectedTime.available" :translations="translations" v-if="selectedTime" />

    <div v-if="selectedTicketKind">
      <input type="hidden" :name="`cart[cart_items_attributes[0][ticket_kind_id]`" :value="selectedTicketKind.id">
      <input type="hidden" :name="`cart[cart_items_attributes[0][quantity]`" value="1">
      <input type="hidden" :name="`cart[cart_items_attributes[0][api_options_text]`" :value="JSON.stringify({prioticket_order: ticketSelection, datetime: dateTime, toDateTime: toDateTime})">
      <input type="hidden" :name="`cart[cart_items_attributes[0][chosen_date]`" v-model="dateTime">
      
    </div>

    <div v-if="showSummary">
      <center>
        <h4>{{ translations.summery }}</h4>
        {{ translations.chosen_date_and_time }}: <strong>{{ dateString }}</strong>
      </center>
      <ul style="margin-top: 10px; margin-bottom: 10px;">
        <li v-for="(ticketKind, key) in ticketKinds" :key="key" v-if="cart[ticketKind.id] && cart[ticketKind.id] > 0">
          {{ cart[ticketKind.id] }} &times; {{ ticketKind.name }}
        </li>
      </ul>    
    </div>
    <div v-if="prioticketTickets.length">
      <button type="submit" class="btn btn-success btn-block" :data-disable-with="translations.reservation_success" style="margin-top: 10px;" :disabled="btnDisabled || loading">{{ translations.confirm }}</button>
    </div>

  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker'
import PrioTicketTicketSelector from './PrioTicketTicketSelector.vue'
import axios from 'axios'

export default {

  components: { datePicker, PrioTicketTicketSelector },
  props: ['ticketKinds', 'formId', 'cartKey', 'translations', 'locale'],
  data () {
    return {
      prioticketTickets: [],
      loadingDates: false,
      currentStep: 'selectTickets',
      cart: {},
      ticketSelection: [],
      dateString: '',
      selectedTime: null,
      selectedTicketKind: null,
      date: null,
      dateTime: null,
      toDateTime: null,
      dates: [],
      loadingTimes: false,
      times: [],
      loading: false,
      showSummary: false
    }
  },
  created() {
    // this.fetchDates()
    if (this.ticketKinds.length == 1) {
      this.selectTicketKind(this.ticketKinds[0])
    }
  }, 
  methods: {
    resetDefault() {
      this.prioticketTickets = []
      this.currentStep = 'selectTickets'
      this.cart = {}
      this.loadingDates = false
      this.ticketSelection = []
      this.dateString = ''
      this.selectedTime = null
      this.selectedTicketKind = null
      this.date = null
      this.dateTime = null
      this.toDateTime = null
      this.dates = []
      this.loadingTimes = false
      this.times = []
      this.loading = false
      this.showSummary = false
    },
    changeTicketSelection(selection) {
      this.ticketSelection = selection
    },
    selectTicketKind(tk) {
      this.resetDefault()
      this.selectedTicketKind = tk

      this.fetchDates()
    },
    fetchTickets() {
      axios.post('/partners/prioticket-tickets.json', {
        form_id: this.formId,
        ticket_kind_id: this.selectedTicketKind.id,
        date: this.date
      }).then((response) => {
        // TODO
        // console.log(response)
        this.prioticketTickets = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    fetchProduct() {
      axios.post('/partners/prioticket-product.json', {
        form_id: this.formId,
        ticket_kind_id: this.selectedTicketKind.id 
      }).then((response) => {
        // TODO
        // console.log(response)
        this.ticketDetails = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },
    priceFormatter(price) {
      return new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR'
      }).format(price / 100.0)
    },
    setLoading(){
      this.loading = true
    },
    chooseTime(){
      this.dateTime = this.selectedTime.time_with_date
      this.toDateTime = this.selectedTime.to_time_with_date
      this.showSummary = true
      this.dateString = this.selectedTime.date_string
      // window.scrollBy(0, 60)
      // setTimeout(function () { 
      //   document.getElementById('new_cart').submit()
      // }, 300)
    },
    dateChanged() {
      this.loadingTimes = true
      this.dateTime = null
      this.showSummary = false
      this.selectedTime = null
      this.prioticketTickets = []
      this.times = []
      this.dateString = ''
      this.fetchTickets()
      axios.post('/partners/prioticket-times.json', {
        form_id: this.formId,
        ticket_kind_id: this.selectedTicketKind.id,
        date: this.date
      }).then((response) => {
        this.times = response.data
        // console.log(response)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        this.loadingTimes = false
      });
    },
    fetchDates() {
      this.dateTime = null
      this.loadingDates = true
      this.showSummary = false
      this.selectedTime = null
      this.prioticketTickets = []
      this.times = []
      this.dateString = ''
      console.log('fetchnig dates')
      axios.post('/partners/prioticket-dates.json', {
        form_id: this.formId,
      }).then((response) => {
        // TODO
        // console.log(response)
        this.loadingDates = false
        this.dates = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });

      
    },
    selectTickets(event) {
      this.currentStep = 'selectTickets'
    
      this.dateTime = null
      this.date = null
      this.cart = {}
      this.showSummary = false
      this.times = []
      event.preventDefault()
    },
    selectDate(event) {
      this.currentStep = 'selectDate'
      event.preventDefault()
    },
    changeSelection(ticketKindId, quantity) {
      console.log(`changes: ${ticketKindId}: ${quantity}`)
      // console.log('changeSelection')
      let cart = {...this.cart}
      cart[ticketKindId] = quantity
      let newCart = {}
      for (var i = Object.keys(cart).length - 1; i >= 0; i--) {
        const key = Object.keys(cart)[i]
        if (cart[key] > 0) {
          newCart[key] = cart[key]
        }
      }
      this.cart = newCart
      // this.sendHeight()
    }
  },
  computed: {
    btnDisabled() {
      return (this.ticketSelection.length == 0 || !this.showSummary)
    },
    options() {
      return {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: moment().format('YYYY-MM-DD'),
        calendarWeeks: false,
        locale: this.locale,
        enabledDates: this.dates
      }
    },
    cartKeys() {
      return Object.keys(this.cart)
    },
    totalSelected() {
      let total = 0
      const cart = this.cart
      Object.keys(cart).map(function(key, index) {
        total += cart[key]
      })
      return total
    },
  }
}
</script>