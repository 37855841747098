<template>
	<div class="container">
	  <div class="row margin-top-50">
	  	<div class="col-md-10 col-md-offset-1">
	    	<Breadcrumb :items="items" />
	  	</div>
	  </div>
	  <div class="row mt-3">
	    <div class="col-md-10 col-md-offset-1">
	      <h1 class="h1 font-weight-bold">Digitale cadeaukaart</h1>
	    </div>
	  </div>
	  <div class="row mt-4">
	    <div class="col-md-10 col-md-offset-1">
	      <div class="bg-white rounded shadow box-padding">
	      	<h2>Kies een onderwerp</h2>
	      	<div class="row">
	        	<div v-for="(design, key) in designs" :key="key" class="col-md-3" style="margin-top: 15px;">
	        		<img @click="selectDesign(design.id)" width="100%" height="175px" :src="`/images/giftcards/${design.id}.jpg`">
	        		<button @click="selectDesign(design.id)" class="radio-button" v-bind:class="{ selected: isSelected(design.id) }"></button><span @click="selectDesign(design.id)">{{design.text}}</span>
	        	</div>
	      	</div>
	      </div>
	    </div>
	  </div>
	  <div class="row mt-4">
	    <div class="col-md-10 col-md-offset-1">
	      <div class="bg-white rounded shadow box-padding">
	      	<h2>Kies een bedrag</h2>
	      	<div class="row">
	      		<div class="col-md-5">
	      			<button @click="chooseAmount(5)" v-bind:class="{ active: isActiveAmount(5) }" class="price-button">€5</button>
	      			<button @click="chooseAmount(10)" v-bind:class="{ active: isActiveAmount(10) }" class="price-button" >€10</button>
	      			<button @click="chooseAmount(15)" v-bind:class="{ active: isActiveAmount(15) }" class="price-button">€15</button>
	      			<button @click="chooseAmount(20)" v-bind:class="{ active: isActiveAmount(20) }" class="price-button">€20</button>
	      			<button @click="chooseAmount(25)" v-bind:class="{ active: isActiveAmount(25) }" class="price-button">€25</button>
	      			<button @click="chooseAmount(50)" v-bind:class="{ active: isActiveAmount(50) }" class="price-button">€50</button>
	      			<button @click="chooseAmount(75)" v-bind:class="{ active: isActiveAmount(75) }" class="price-button">€75</button>
	      			<button @click="chooseAmount(100)" v-bind:class="{ active: isActiveAmount(100) }" class="price-button">€100</button>
	      			<button @click="chooseAmount(150)" v-bind:class="{ active: isActiveAmount(150) }" class="price-button">€150</button>
	      			<button @click="chooseAmount(200)" v-bind:class="{ active: isActiveAmount(200) }" class="price-button">€200</button>
	      			<button @click="chooseAmount(250)" v-bind:class="{ active: isActiveAmount(250) }" class="price-button">€250</button>
	      			<div class="d-inline-block mt-2">
	      				<span class="mt-1 mr-1 font-weight-bold">Of vul zelf een bedrag in</span>
	      				<input v-model="customAmount" @change="setAmount($event)" class="price-input" placeholder="€" />
	      			</div>
	      		</div>
	      		<div class="col-md-offset-1 col-md-6">
	      			<h3>Handig om te weten</h3>
	      			<ul>
	      				<li v-for="(fact, key) in facts" :key="key"><i class="far fa-lightbulb mr-1 text-warning mr-1"></i>{{fact}}</li>
	      			</ul>
	      		</div>
	      	</div>
	      </div>
	    </div>
	  </div>
	  <div class="row mt-4">
	    <div class="col-md-10 col-md-offset-1">
	      <div class="">
	      	<div class="row">
	      		<div class="col-md-6">
	      			<div class="bg-white rounded shadow box-padding">
	      				<h2>Ontvanger</h2>
	      				<div class="row">
	      					<div class="col-md-6">
		      				 	<div class="form-group">
								    	<label for="firstName">Voornaam</label>
								    	<input @change="replaceName" v-model="giftcard.firstName" type="name" class="form-control" id="firstName">
								  	</div>
	      					</div>
	      					<div class="col-md-6">
		      				 	<div class="form-group">
								    	<label for="lastName">Achternaam</label>
								    	<input v-model="giftcard.lastName" type="name" class="form-control" id="lastName">
								  	</div>
	      					</div>
	      				</div>
	      				<div class="row">
	      					<div class="col-md-12">
	      						<div class="form-group">
								    	<label for="email">E-mailadres</label>
								    	<input v-model="giftcard.email" type="email" class="form-control" id="email">
								  	</div>
	      					</div>
	      				</div>
	      				<div class="row mt-3">
	      					<div class="col-md-12">
	      						<h3>Persoonlijke boodschap (optioneel)</h3>
	      						<p>Schrijf hier je eigen boodschap voor de ontvanger. Wij hebben vast een opzetje voor je gemaakt! Ben zo al tevreden met de tekst? Ga dan meteen door naar bestellen.</p>
	      						<div class="form-group">
								    	<textarea rows="6" v-model="giftcard.message" type="text" class="form-control" id="message"/>
								  	</div>
	      					</div>
	      				</div>
	      			</div>
	      		</div>
	      		<div class="col-md-6">
	      			<div class="bg-white rounded shadow box-padding">
	      				<h2>Voorbeeld</h2>
	      				<h3>Wat de ontvanger ziet</h3>
	      				<div class="row mt-3">
	      					<div class="col-md-6 col-md-offset-3">
	      						<img class="digital" width="100%" :src="`/images/giftcards/${giftcard.design}.jpg`">
	      					</div>	
	      				</div>
	      				<div class="row mt-2">
	      					<div class="col-md-6 col-md-offset-3">
	      						<span class="price">€{{giftcard.amount}}</span>
	      					</div>	
	      				</div>
	      				<div class="row mt-2">
	      					<div class="col-md-8 col-md-offset-2">
	      						<p class="message" style="white-space: pre-line"> {{ giftcard.message }}</p>
	      					</div>	
	      				</div>
	      				<div class="row mt-2">
	      					<div class="col-md-8 col-md-offset-2">
	      						<small>De ontvanger krijgt naast je bericht ook een instructie hoe hij de cadeaukaart kan gebruiken en tot wanneer deze geldig is.</small>
	      					</div>	
	      				</div>
	      			</div>
	      		</div>
	      	</div>
	      </div>
	    </div>
	  </div>
	  <div class="row mt-4 mb-4">
	    <div class="col-md-10 col-md-offset-1">
	      <div class="bg-white rounded shadow box-padding">
	      	<div class="row mt-2">
	      		<div class="col-md-12">
	      			<div class="float-right">
	      				<button class="order-more-button" @click="order(false)" :disabled="!isReady">Nog een cadeaukaart bestellen</button>
	      				<button class="order-button" @click="order(true)" :disabled="!isReady">Cadeaukaart bestellen</button><br/>
	      				<small class="float-right" v-bind:class="{ 'd-none': isReady }">Nog niet alle gegevens zijn ingevuld</small>
	      			</div>
	      		</div>
	      	</div>
	      </div>
	    </div>
	  </div>
	</div>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue'
import axios from 'axios'
	export default {
		name: "DigitalGiftcard",
		components: {
			Breadcrumb
		},
		computed: {
			message() {
				return this.giftcard.message.replace(/(?:\r\n|\r|\n)/g, '<br />')
			},
			isReady() {
				if(this.giftcard.design && this.giftcard.amount && this.giftcard.firstName && this.giftcard.lastName && this.giftcard.email && this.giftcard.message) {
					return true
				} else {
					return false
				}
			}
		},
		props: ["cart"],
		methods: {
			isActiveAmount(value) {
				if(this.giftcard.amount == value) {
					return true;
				} else {
					return false;
				}
			},
			isSelected(id) {
				if (this.giftcard.design == id) {
					return true
				} else {
					return false
				}
			},
			selectDesign(id) {
				this.giftcard.design = id
			},
			chooseAmount(value) {
				this.giftcard.amount = value
			},
			setAmount(event){
				if(event.srcElement.value > 4) {
					this.giftcard.amount = event.srcElement.value
				} else {
					this.giftcard.amount = 5
					this.customAmount = 5
				}
			},
			replaceName() {
				this.giftcard.message = this.giftcard.message.replace("<voornaam>", this.giftcard.firstName);
			},
			order(toCart) {
        axios.post("/cadeaukaart/toevoegen.json", {
        	digital: true, 
        	cart: this.cart,
        	design: this.giftcard.design,
        	quantity: 1,
        	amount: this.giftcard.amount,
        	first_name: this.giftcard.firstName,
        	last_name: this.giftcard.lastName,
        	email: this.giftcard.email,
        	message: this.giftcard.message
        }).then(response => {
        	if(toCart) {
        		window.location.href = 	`/carts/${response.data.cart}`;
        	} else {
        		window.location.href = 	`/cadeaukaart/digitaal`;
        	}
        })
			}
		},
		data() {
			return {
				designs: [{id: 1, text: "Happy birthday"}, {id: 2, text: "Gefeliciteerd!"}, {id: 3, text: "Voor jou!"}, {id: 4, text: "Geslaagd!"}, {id: 5, text: "Ho Ho Ho!"}, {id: 6, text: "Merry Christmas"}, {id: 7, text: "Sterkte"}, {id: 8, text: "Bedankt!"},{id: 9, text: "Happy Valentine"}, {id: 10, text: "Sorry"}, {id: 11, text: "Cadeautje"}, {id: 12, text: "Van ons allemaal"}],
				items: [{text: "DagjeWeg.Tickets", url: "/"}, {text: "Cadeaukaarten", url: "/cadeaukaart"}, {text: "Digitale cadeaukaart"}],
				facts: ["Een ticket voor een volwassene kost gemiddeld €16,88.", "Een ticket voor een kind kost gemiddeld €12,54.", "Een gemiddeld uitstapje kost €48 euro voor 2 volwassenen en 1 kind.", "Het gekozen bedrag moet minimaal €5 zijn.", "Het tegoed is 5 jaar geldig en kan in delen besteed worden."],
				customAmount: null,
				giftcard: {
					design: 1,
					amount: 25,
					firstName: "",
					lastName: "",
					email: "",
					message: "Beste <voornaam>,\n\nGeniet met deze cadeaukaart van een heerlijke dag weg."
				}
			}
		}
	};
</script>

<style scoped>
</style>