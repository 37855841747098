<template>

  <div class="rounded p-3 shadow-sm">
    <center>
      <h4>Bestelling</h4>
      <strong>{{ cart.name }}</strong>
    </center>
    
    <table class="table my-5">
      <tr v-for="(ci, key) in cartItems" :key="key">
        <td class="text-right">{{ ci.quantity }} &times;</td>
        <td>{{ ci.name }}</td>
        <td>
          <span v-if="ci.reservation_date_time">{{ ci.reservation_date_time }}</span>
          <em v-if="!ci.reservation_date_time">geen datum gekozen</em>
        </td>
      </tr>
    </table>

    <div class="text-center text-muted my-2" v-if="loading || isLoading">
      <em>
        Even geduld...
      </em>
    </div>
    <a :class="{'disabled':loading}" class="btn btn-outline-primary btn-block" :href="`/carts/${cart.key}/download_tickets`">Download tickets</a>
    <button @click="sendEmail" :disabled="loading || isLoading || sendingMail" v-if="cart.email && cart.email.length" class="btn btn-outline-primary btn-block mt-2"> 
      Verstuur e-mail met tickets naar<br>{{ cart.email}}
    </button>
    <div v-if="mailSent" class="text-success text-center mt-2">
      De tickets zijn verstuurd naar {{ cart.email }}!
    </div>
    

  </div>



</template>

<script>
import axios from 'axios'
export default {

  name: 'BackofficeCartOverview',
  props: ['cart', 'cartItems', 'loading'],
  data () {
    return {
      mailSent: false,
      sendingMail: false,
      isLoading: false
    }
  },
  methods: {
    sendEmail(){
      this.sendingMail = true
      axios.get(`/carts/${this.cart.key}/resend_cart_email.json`).then((response) => {
        this.sendingMail = false
        this.mailSent = true
        setTimeout(function () { 
          this.mailSent = false
        }.bind(this),3000)
        // TODO
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
    },

  }
}
</script>

<style lang="css" scoped>
</style>