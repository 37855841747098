<template>
	<div class="bg-white p-3">
		<h3 class="mb-2">Veelgestelde vragen</h3>
		<ul>
			<Question v-for="(question, key) in questions" :question="question" :key="key" />
		</ul>
	</div>
</template>

<script>
import Question from "./Question.vue"
	export default {
		name: "FAQ",
		props: ["questions"],
		components: {
			Question
		}
	};
</script>

<style scoped>
</style>