<template>
  <div>
    <table class="table" v-if="!adminMode">
      <tbody>
        <tr v-for="(tableRow, key) in tableRows" :key="key">
          <th class="text-left px-0">{{ tableRow.name }}</th>
          <td class="text-right px-0">{{ tableRow.price }}</td>
          <td class="text-right px-0">&times;&nbsp;{{ tableRow.quantity }}</td>
          <th class="text-right px-0">{{ tableRow.total }}</th>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2" class="text-left px-0">{{ translations.total }}</td>
          <td class="text-right px-0">&times;&nbsp;{{ tableRows.reduce((acc, item) => acc + item.quantity, 0) }}</td>
          <th class="text-right px-0">{{ formattedTotal }}</th>
        </tr>
      </tfoot>
    </table>

    <div 
      v-if="tableRow.ticketKind.ticket_kind.require_voucher" 
      v-for="(tableRow, key) in tableRows" :key="key"
      class="bg-light p-3 rounded mb-3"
    >
      <center><h4>{{ translations.enter_voucher_codes }}</h4></center>
      <DWTVoucherInput 
        v-for="(index, key) in tableRow.quantity"
        :index="index"
        :key="key"
        :voucher-code="voucherCodes[`ticket_kind_${tableRow.ticketKind.ticket_kind.id}`][index-1]" 
        :ticket-kind="tableRow.ticketKind.ticket_kind"
        :ticket-kind-id="tableRow.ticketKind.ticket_kind.id" 
        :codes="voucherCodes[`ticket_kind_${tableRow.ticketKind.ticket_kind.id}`].map(obj => obj.code)"
      />
    </div>

    <div class="col-12" v-if="!adminMode">
      <div class="alert alert-danger" v-if="isInvalid">
        {{ message }}
      </div>
      <div class="alert alert-success" v-if="isValid">
        {{ message }}
      </div>
    </div>
    <div v-if="checking" class="col-12 text-center mb-2">
      <i class="fa fa-spinner fa-spin"></i> <em>{{ translations.checking_availability }}</em>
    </div>

    <div class="col-12 text-right" v-if="!adminMode">
      <div @click="makeReservation" class="btn btn-success btn-block" :disabled="checking || loading || hasInvalidVoucherCodes">{{ translations.ready_book }}</div>
    </div>

    <table class="table table-striped" v-if="adminMode">
      <tr v-for="(tableRow, key) in tableRows" :key="key">
        <th class="text-left px-0">{{ tableRow.name }}</th>
        <td class="text-right px-0">{{ tableRow.price }}</td>
        <td class="text-right px-0">&times;&nbsp;{{ tableRow.quantity }}</td>
        <th class="text-right px-0">{{ tableRow.total }}</th>
      </tr>
      <tr>
        <td colspan="2" class="text-left px-0">{{ translations.total }}</td>
        <td class="text-right px-0">&times;&nbsp;{{ tableRows.reduce((acc, item) => acc + item.quantity, 0) }}</td>
        <th class="text-right px-0">{{ formattedTotal }}</th>
      </tr>
    </table>
    <div class="row" v-if="adminMode">
      <div class="col-12" v-if="!completedCart">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="first_name">Voornaam<sup>*</sup></label>
              <input type="email" class="form-control" id="first_name" v-model="firstName">
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="last_name">Achternaam<sup>*</sup></label>
              <input type="email" class="form-control" id="last_name" v-model="lastName">
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input type="email" class="form-control" id="email" v-model="email">
        </div>

        <div class="form-group" v-if="isRetail">
          <label for="order_number">Order nummer</label>
          <input type="text" class="form-control" id="order_number" v-model="order_number">
        </div>

        <div class="form-group">
          <label for="comment">Opmerking</label>
          <textarea class="form-control" id="comment" v-model="comment" rows="3" />
        </div>
        <div class="bg-white p-3 rounded mb-2" v-if="!isRetail">
          <div class="form-check">
            <input type="radio" class="form-check-input" id="reservation" value="reservation" v-model="reservationType">
            <label class="form-check-label" for="reservation">Tijdsloten afboeken</label>
          </div>
          <p class="small text-muted" v-if="reservationType == 'reservation'">Als je een reservering maakt door tijdsloten af te boeken, maak je een reservering op de naam van deze klant, zonder dat er echt tickets worden gemaakt. De reservering is wel definitief.</p>
          <div class="form-check">
            <input type="radio" class="form-check-input" id="ticket" value="tickets" v-model="reservationType">
            <label class="form-check-label" for="ticket">Tickets reserveren</label>
          </div>
          <p class="small text-muted" v-if="reservationType == 'tickets'">Als je een reservering maakt met tickets, dan worden er voor deze klant tickets met barcodes gemaakt, die ook in de administratie verschijnen. Deze tickets worden gemarkeerd als 'handmatig betaald'.</p>

        </div>
      </div>
      <div class="col-12">
        <div class="alert alert-danger" v-if="isInvalid">
          {{ message }}
        </div>
        <div class="alert alert-success" v-if="isValid">
          {{ message }}
        </div>
      </div>
      <div v-if="checking" class="col-12 text-center mb-2">
        <i class="fa fa-spinner fa-spin"></i> <em>{{ translations.checking_availability }}</em>
      </div>
      <div v-if="loading" class="col-12 text-center mb-2">
        <i class="fa fa-spinner fa-spin"></i>
      </div>

      <BackofficeCartOverview 
        v-if="completedCart"
        class="bg-white w-75 mx-auto my-3" 
        ref="cart" 
        :cart="completedCart" 
        :cart-items="completedCartItems"
        :loading="loading"
      >  
      </BackofficeCartOverview>


      <div class="col-12">
        <div @click="makeReservation" class="btn btn-success btn-block" :disabled="loading || checking || hasMissingFields">Reserveren</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DWTVoucherInput from '../Partners/DWTVoucherInput'
import BackofficeCartOverview from '../Admin/BackofficeCartOverview'
export default {

  name: 'DWTCart',
  props: ['items', 'ticketKinds', 'capacityPlannerId', 'adminMode', 'formId', 'blockId', 'date', 'cartKey', 'isEmbedded', 'translations', 'locale', 'isRetail'],
  components: {BackofficeCartOverview, DWTVoucherInput},
  watch: {
    items: {
      handler: function(oldVal, newVal) {
        // console.log('items changed!')
        this.isInvalid = false
      }
    }
  },
  data () {
    return {
      checking: false,
      firstName: '',
      lastName: '',
      email: '',
      order_number: '',
      comment: '',
      completedCart: null,
      completedCartItems: [],
      voucherCodes: {},
      isInvalid: false,
      isValid: false,
      reservationType: null,
      loading: false,
      message: ''

    }
  },
  computed: {
    hasMissingFields() {
      return (this.hasInvalidVoucherCodes || this.reservationType == null  || (this.firstName == '' || this.lastName == '') && this.comment == '')
    },
    invalidVoucherCodes() {
      let missing = []
      for (var i = this.tableRows.length - 1; i >= 0; i--) {
        const tk = this.tableRows[i].ticketKind.ticket_kind
        if (tk.require_voucher) {
          const codes = this.voucherCodes[`ticket_kind_${tk.id}`]
          for (var j = codes.length - 1; j >= 0; j--) {
            if (codes[j].valid == false) {
              missing.push(codes[j])
            }
          }
        }
      }
      return missing
    },
    hasInvalidVoucherCodes(){
      return this.invalidVoucherCodes.length > 0
    },
    total() {
      let tot = 0
      for (var i = Object.keys(this.items).length - 1; i >= 0; i--) {
        var tkid = parseInt(Object.keys(this.items)[i])
        for (var j = this.ticketKinds.length - 1; j >= 0; j--) {
          if (parseInt(this.ticketKinds[j].ticket_kind.id) == tkid) {
            tot += this.ticketKinds[j].ticket_kind.price_in_cents*this.items[tkid]
          }
        }
      }
      return tot
    },
    formattedTotal() {
      return this.priceFormatter(this.total)
    },
    tableRows() {
      let rows = []
      for (var i = Object.keys(this.items).length - 1; i >= 0; i--) {
        var tkid = parseInt(Object.keys(this.items)[i])
        // console.log(`checking ${tkid}`)
        // get ticket kind
        for (var j = this.ticketKinds.length - 1; j >= 0; j--) {
          // console.log(`checking if ${this.ticketKinds[j].ticket_kind.id} === ${tkid}`)
          if (parseInt(this.ticketKinds[j].ticket_kind.id) == tkid) {
            rows.unshift({
              name: this.ticketKinds[j].ticket_kind.name,
              price: this.priceFormatter(this.ticketKinds[j].ticket_kind.price_in_cents),
              quantity: this.items[tkid],
              ticketKind: this.ticketKinds[j],
              total: this.priceFormatter(this.ticketKinds[j].ticket_kind.price_in_cents*this.items[tkid])
            })  

          }
        }
      }
      return rows
    }
  },
  mounted() {
    if (this.isRetail) {
      this.reservationType = 'tickets'
    }
  },
  created() {
    this.setupVoucherCodes()
  },
  beforeUpdate() {
    this.setupVoucherCodes()
  },
  methods: {
    setupVoucherCodes(){
      let codes = {...this.voucherCodes}
      for (var i = this.tableRows.length - 1; i >= 0; i--) {
        const tk = this.tableRows[i].ticketKind.ticket_kind
        if (tk.require_voucher) {
          let list = codes[`ticket_kind_${tk.id}`]
          if (list == undefined) {
            list = codes[`ticket_kind_${tk.id}`] = []            
          }
          for(var j=0; j < this.tableRows[i].quantity; j++){
            if (list[j] == undefined) {
              list.push({code: '', ticket_kind_id: tk.id, valid: false})
            }
          }
          // force max length
          list.length = this.tableRows[i].quantity
        }
      }
      this.voucherCodes = codes
    },
    makeReservation() {
      this.checking = true
      // axios.post(`/partners/dwt/dates.json`, {planning_id: this.capacityPlannerId, form_id: this.formId})
      const reservation = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        comment: this.comment
      }
      this.$emit('update')
      return axios.post(`/partners/dwt/reservation.json`, { 
          form_id: this.formId, 
          order_number: this.order_number,
          reservation: reservation, 
          items: this.items, 
          planning_id: this.capacityPlannerId, 
          date: this.date,
          cart_key: this.cartKey,
          is_embedded: this.isEmbedded,
          locale: this.locale,
          force_locale: true,
          reservation_type: this.reservationType,
          block_id: this.blockId ,
          voucher_codes: this.voucherCodes
        })
        .then(response => {
          this.checking = false

          // console.log(response)
          this.message  =  response.data.message
          if (response.data.valid) {
            this.isInvalid = false
            this.isValid = true
            this.message = response.data.message
            this.firstName = ''
            this.lastName = ''
            this.email = ''
            this.comment = ''
          } else {
            this.isInvalid  = true
          }
          if (response.data.redirect_url) {
            this.loading = true
            // this.checking = true
            console.log(`redirecting to ${response.data.redirect_url}`)
            this.checking = false
            setTimeout(function () { 
              console.log("redirecting")
              this.loading = true
              window.top.location.href = response.data.redirect_url
            }.bind(this), 300)
          } else {
            console.log('not redirecting')
            console.log(response.data)
            if (response.data.cart) {
              this.completedCart = response.data.cart
              this.completedCartItems = response.data.cart_items  
              this.$refs.cart.$el.classList.add("animated")
              this.$refs.cart.$el.classList.add("tada")
            }
            
          }
          this.$emit('update')
          // this.theFormItems = response.data
        })
        .catch(error => {
          console.log(error)
          this.$emit('update')
        })

    },
    priceFormatter(price) {
      return new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR'
      }).format(price / 100.0)
    }
  }
}
</script>

<style lang="css" scoped>

</style>