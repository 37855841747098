import Vue from 'vue/dist/vue.esm'
import axios from 'axios'
import 'moment/locale/nl.js';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

import EnvisoDatePicker from '../components/Partners/EnvisoDatePicker.vue'
Vue.component('envisodatepicker', EnvisoDatePicker)

import DWTConsumerDatePicker from '../components/Partners/DWTConsumerDatePicker.vue'
Vue.component('dwtconsumerdatepicker', DWTConsumerDatePicker)

import TicketCounterConsumerDatePicker from '../components/Partners/TicketCounterConsumerDatePicker.vue'
Vue.component('ticketcounterconsumerdatepicker', TicketCounterConsumerDatePicker)

import GlobalTicketConsumerDatePicker from '../components/Partners/GlobalTicketConsumerDatePicker.vue'
Vue.component('globalticketconsumerdatepicker', GlobalTicketConsumerDatePicker)

import PrioTicketConsumerPicker from '../components/Partners/PrioTicketConsumerPicker.vue'
Vue.component('prioticketconsumerpicker', PrioTicketConsumerPicker)

import LeisureKingReservation from '../components/Partners/LeisureKingReservation.vue'
Vue.component('leisurekingreservation', LeisureKingReservation)

import DWTDateChanger from '../components/Admin/DWTDateChanger.vue'
Vue.component('dwtdatechanger', DWTDateChanger)

import ConsumerGiftcards from '../components/Consumer/ConsumerGiftcards.vue'
Vue.component('consumergiftcards', ConsumerGiftcards)

import HelpIcon from '../components/Consumer/HelpIcon.vue'
Vue.component('helpicon', HelpIcon)

import Questions from '../components/Consumer/Questions.vue'
Vue.component('questions', Questions)

import GiftcardIndex from '../components/Giftcard/GiftcardIndex.vue'
Vue.component('giftcardindex', GiftcardIndex)

import DigitalGiftcard from '../components/Giftcard/DigitalGiftcard.vue'
Vue.component('digitalgiftcard', DigitalGiftcard)

import PhysicalGiftcard from '../components/Giftcard/PhysicalGiftcard.vue'
Vue.component('physicalgiftcard', PhysicalGiftcard)

import ArticleShopIndex from '../components/Articles/ArticleShopIndex.vue'
Vue.component('articleshopindex', ArticleShopIndex)

window.addEventListener("load", function(){
  if (document.getElementById("frontend")) {
    if(document.getElementById("frontend").dataset.useVue == 'true') {
      const app = new Vue({
        el: '#frontend',
      })
    }
  }
  if (document.getElementById("frontend2")) {
    if(document.getElementById("frontend2").dataset.useVue == 'true') {
      console.log("#frontend2 found")
      const frontend2 = new Vue({
        el: '#frontend2',
      })
    }
  }
});
