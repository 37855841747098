<template>
  <div>
    <div v-if="!message.success">
      <div class="form-group">
        <label for="email">{{ translations.form.email }}</label>
        <input v-model="email" type="email" class="form-control" id="email" aria-describedby="emailHelp">
        <small id="emailHelp" class="form-text text-muted">{{ translations.email_private }}</small>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="first_name">{{ translations.form.first_name }}</label>
            <input v-model="first_name" type="text" class="form-control" id="first_name">
          </div>
        </div>  
        <div class="col">
          <div class="form-group">
            <label for="last_name">{{ translations.form.last_name }}</label>
            <input v-model="last_name" type="text" class="form-control" id="last_name">
          </div>
        </div>
      </div>
      
      <div class="form-group" v-if="pickup">
        <label for="last_name">{{ translations.form.order_number }}</label>
        <input v-model="order_number" type="text" class="form-control" id="last_name">
        <small id="orderNumberHelp" class="form-text text-muted">Heb je een ordernummer heb je bij de bevestiging van je bestelling ontvangen? Vul deze dan hier in.</small>
      </div>

      <div class="form-group" v-if="!pickup">
        <label for="last_name">{{ translations.form.telephone_number }}</label>
        <input v-model="order_number" type="text" class="form-control" id="last_name">
        <small id="orderNumberHelp" class="form-text text-muted">Vul hier je telefoonnummer in zodat wij je kunnen bereiken mocht er iets veranderen in de planning.</small>
      </div>
    </div>
    <div class="alert alert-success" v-if="message.success">
      {{ message.body }}
    </div>
    <div class="alert alert-danger" v-if="message.error">
      {{ message.body }}
    </div>

    <button v-if="!message.success" :disabled="hasMissingFields" class="btn btn-block btn-success" @click="sendRetailConfirmation">
      <i class='fa fa-spinner fa-spin' v-if="loading"></i>
      {{ translations.confirm }}
    </button>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'RetailConfirmationForm',
  props: ['translations', 'formId', 'cart', 'date', 'timeSlot', 'pickup'],
  data () {
    return {
      email: '',
      first_name: '',
      last_name: '',
      order_number: '',
      loading: false,
      message: {}
    }
  },
  computed: {
    hasMissingFields() {
      return this.loading || !this.email.length || !this.first_name.length || !this.last_name.length || !this.order_number.length
    }
  },
  methods: {
    sendRetailConfirmation() {
      this.loading = true
      this.message = {}
      axios.post(`/retail-forms/${this.formId}`, {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        order_number: this.order_number,
        date: this.date,
        block_id: this.timeSlot.id
      }).then((response) => {
        console.log(response.data)
        this.message = response.data
        // TODO
      }).catch((error) => {
        console.error(error);
        this.message = error.data
      }).finally((response) => {
        this.loading = false
        
        // TODO
      });
      // if (this.hasValidEmail()) {
      //   console.log("Valid email!") 
      // } else {
      //   console.log("You have entered an invalid email address!") 
      //   return (false)
      // }
    },
    hasValidEmail() {
      (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.email))
    }
  }
}
</script>

<style lang="css" scoped>
</style>