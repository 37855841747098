<template>
	<div class="container">
	  <div class="row margin-top-50">
	  	<div class="col-md-10 col-md-offset-1">
	    	<Breadcrumb :items="items" />
	  	</div>
	  </div>
	  <div class="row mt-3">
	    <div class="col-md-10 col-md-offset-1">
	      <h1 class="h1 font-weight-bold">Cadeaukaart</h1>
	    </div>
	  </div>
	  <div class="row mt-4">
	    <div class="col-md-10 col-md-offset-1">
	      <div class="bg-white rounded shadow box-padding">
	      	<div class="row">
	      		<div class="col-md-5">
	      			<div class="row">
	      				<h2>Aantal cadeaukaarten</h2>
	      				<div class="col-md-4">
		      				<div class="input-group mb-3">
									  <div class="input-group-prepend">
									    <button @click="decreaseQuantity" class="input-group-text">-</button>
									  </div>
									  <input class="form-control" v-model="giftcard.quantity">
									  <div class="input-group-append">
									    <button @click="increaseQuantity" class="input-group-text">+</button>
									  </div>
									</div>
								</div>
	      			</div>
	      			<div class="row">
	      				<h2>Kies een bedrag</h2>
		      			<button @click="chooseAmount(10)" v-bind:class="{ active: isActiveAmount(10) }" class="price-button" >€10</button>
		      			<button @click="chooseAmount(15)" v-bind:class="{ active: isActiveAmount(15) }" class="price-button">€15</button>
		      			<button @click="chooseAmount(20)" v-bind:class="{ active: isActiveAmount(20) }" class="price-button">€20</button>
		      			<button @click="chooseAmount(25)" v-bind:class="{ active: isActiveAmount(25) }" class="price-button">€25</button>
		      			<button @click="chooseAmount(50)" v-bind:class="{ active: isActiveAmount(50) }" class="price-button">€50</button>
		      			<button @click="chooseAmount(75)" v-bind:class="{ active: isActiveAmount(75) }" class="price-button">€75</button>
		      			<button @click="chooseAmount(100)" v-bind:class="{ active: isActiveAmount(100) }" class="price-button">€100</button>
		      			<button @click="chooseAmount(150)" v-bind:class="{ active: isActiveAmount(150) }" class="price-button">€150</button>
		      			<button @click="chooseAmount(200)" v-bind:class="{ active: isActiveAmount(200) }" class="price-button">€200</button>
		      			<button @click="chooseAmount(250)" v-bind:class="{ active: isActiveAmount(250) }" class="price-button">€250</button>
		      			<div class="d-inline-block mt-2">
		      				<span class="mt-1 mr-1 font-weight-bold">Of vul zelf een bedrag in</span>
		      				<input v-model="customAmount" @change="setAmount($event)" class="price-input" placeholder="€" />
		      			</div>
	      			</div>
	      		</div>
	      		<div class="col-md-offset-1 col-md-6">
	      			<h3>Handig om te weten</h3>
	      			<ul>
	      				<li v-for="(fact, key) in facts" :key="key"><i class="far fa-lightbulb mr-1 text-warning"></i>{{fact}}</li>
	      			</ul>
	      		</div>
	      	</div>
	      </div>
	    </div>
	  </div>
	 	<div class="row mt-4 mb-4">
	    <div class="col-md-10 col-md-offset-1">
	      <div class="">
	      	<div class="row">
	      		<div class="col-md-6">
	      			<div class="bg-white rounded shadow box-padding">
	      				<h2>Adresgegevens</h2>
	      				<p>Vul hieronder je adresgegevens in en je krijgt de cadeaukaart zo snel mogelijk thuisgestuurd. Wil je de cadeaukaart rechtstreeks naar de ontvanger sturen? Kies dan voor een <a href="/cadeaukaart/digitaal">digitale cadeaukaart</a>.</p>
	      				<div class="row">
	      					<div class="col-md-9">
		      				 	<div class="form-group">
								    	<label for="street">Straatnaam</label>
								    	<input v-model="giftcard.street" type="street" class="form-control" id="street">
								  	</div>
	      					</div>
	      					<div class="col-md-3">
		      				 	<div class="form-group">
								    	<label for="house_number">Huisnummer</label>
								    	<input v-model="giftcard.house_number" type="house_number" class="form-control" id="house_number">
								  	</div>
	      					</div>
	      				</div>
	      				<div class="row">
	      					<div class="col-md-6">
	      						<div class="form-group">
								    	<label for="postal_code">Postcode</label>
								    	<input v-model="giftcard.postal_code" type="postal_code" class="form-control" id="postal_code">
								  	</div>
	      					</div>
	      					<div class="col-md-6">
		      				 	<div class="form-group">
								    	<label for="city">Plaatsnaam</label>
								    	<input v-model="giftcard.city" type="city" class="form-control" id="city">
								  	</div>
	      					</div>
	      				</div>
	      				<div class="row">
	      					<div class="col-md-12">
	      						<b>Land</b><br/>
	      						<span>Nederland</span>
	      					</div>
	      				</div>
	      			</div>
	      		</div>
	      		<div class="col-md-6">
	      			<div class="bg-white rounded shadow box-padding">
	      				<h2>Voorbeeld</h2>
	      				<h3>Wat krijg je thuisgestuurd?</h3>
	      				<div class="row mt-3">
	      					<div class="col-md-8 col-md-offset-2">
	      						<img src="/images/fysieke_cadeaukaart.png" class="digital">
	      					</div>	
	      				</div>
	      				<div class="row mt-2">
	      					<div class="col-md-12">
	      						<small>De ontvanger krijgt ook een instructie om de cadeaukaart te gebruiken en waarop staat tot wanneer hij geldig is.</small>
	      					</div>	
	      				</div>
	      			</div>
	      		</div>
	      	</div>
	      </div>
	    </div>
	  </div>
	  <div class="row mt-4 mb-4">
	    <div class="col-md-10 col-md-offset-1">
	      <div class="bg-white rounded shadow box-padding">
	      	<div class="row mt-2">
	      		<div class="col-md-12">
	      			<div class="float-right">
	      				<button class="order-more-button" @click="order(false)" :disabled="!isReady">Nog een cadeaukaart bestellen</button>
	      				<button class="order-button" @click="order(true)" :disabled="!isReady">Cadeaukaart bestellen</button><br/>
	      				<small class="float-right" v-bind:class="{ 'd-none': isReady }">Nog niet alle gegevens zijn ingevuld</small>
	      			</div>
	      		</div>
	      	</div>
	      </div>
	    </div>
	  </div>
	</div>
</template>

<script>
import Breadcrumb from './Breadcrumb.vue'
import axios from 'axios'
	export default {
		name: "PhysicalGiftcard",
		components: {
			Breadcrumb
		},
		created() {
			if(this.adress && this.adress.street) {
				this.giftcard.street = this.adress.street
				this.giftcard.house_number = this.adress.house_number
				this.giftcard.postal_code = this.adress.postal_code
				this.giftcard.city = this.adress.city
			} 
		},
		props: ["cart", "adress"],
		computed: {
			isReady() {
				if(this.giftcard.amount && this.giftcard.quantity && this.giftcard.street && this.giftcard.house_number && this.giftcard.postal_code && this.giftcard.city) {
					return true
				} else {
					return false
				}
			}
		},
		methods: {
			isActiveAmount(value) {
				if(this.giftcard.amount == value) {
					return true;
				} else {
					return false;
				}
			},
			increaseQuantity() {
				this.giftcard.quantity = this.giftcard.quantity + 1
			},
			decreaseQuantity() {
				if(this.giftcard.quantity > 1) {
					this.giftcard.quantity = this.giftcard.quantity + 1
				}
			},
			chooseAmount(value) {
				this.giftcard.amount = value
			},
			setAmount(event){
				if(event.srcElement.value > 9) {
					this.giftcard.amount = event.srcElement.value
				} else {
					this.giftcard.amount = 10
					this.customAmount = 10
				}
			},
			order(toCart) {
				if(this.isReady) {
	        axios.post("/cadeaukaart/toevoegen.json", {
        	digital: false, 
        	cart: this.cart,
        	quantity: this.giftcard.quantity,
        	amount: this.giftcard.amount,
        	street: this.giftcard.street,
        	house_number: this.giftcard.house_number,
        	postal_code: this.giftcard.postal_code,
        	city: this.giftcard.city
        }).then(response => {
	        	if(toCart) {
	        		window.location.href = 	`/carts/${response.data.cart}`;
	        	} else {
	        		window.location.href = 	`/cadeaukaart/fysiek`;
	        	}
	        })
				}
			}
		},
		data() {
			return {
				items: [{text: "DagjeWeg.Tickets", url: "/"}, {text: "Cadeaukaarten", url: "/cadeaukaart"}, {text: "Fysieke cadeaukaart"}],
				facts: ["Een ticket voor een volwassene kost gemiddeld €16,88.", "Een ticket voor een kind kost gemiddeld €12,54.", "Een gemiddeld uitstapje kost €48 euro voor 2 volwassenen en 1 kind.", "Het gekozen bedrag moet minimaal €5 zijn.", "Het tegoed is 5 jaar geldig en kan in delen besteed worden."],
				customAmount: null,
				giftcard: {
					amount: 25,
					quantity: 1,
					street: null,
					house_number: null,
					postal_code: null,
					city: null,
				}
			}
		}
	};
</script>

<style scoped>
</style>