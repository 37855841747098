<template>
  <div ref="container">
    <div v-if="!startedBooking">
      <table class="table table">
        <tr v-for="(ticketKind, key) in ticketKindList" :key="key" v-if="!ticketKind.should_auto_choose_quantity" style="border-bottom: 1px solid #333;">
          <td>
            <h4 class="form-ticket-kind-name ticket-name-test" style="font-size: 16px; font-weight: bold; margin-bottom: 2px;">{{ticketKind.form_name}}</h4>
            <p class="form-ticket-kind-description" style="font-size: 13px;" >{{ ticketKind.description }}</p>
          </td>          
          <td style="text-align: right;">
            <del v-if="ticketKind.original_price" class="form-ticket-kind-original old-price">{{ ticketKind.original_price_string }} </del>
          </td>
          <td style="text-align: right;">
            <span class="form-ticket-kind-price ticket-price" style="font-size: 16px; font-weight: 700">{{ ticketKind.price_string }}</span>
          </td>
        </tr>
      </table>

      <div style="text-align: right;">
        <p class="small">{{ translations.reservation_required }}</p>
        <button @click="startBooking" class="btn btn-success">{{ translations.select_a_date }}</button>
      </div>
    </div>
    <div v-if="startedBooking">
      <div class="row">
        <div class="col-md-12" style="text-align: center;">
          <div class="btn-group" role="group">
            <button type="button" @click="selectedTab = 'date'" class="btn btn-outline-primary" :class="{'active' : selectedTab == 'date'}">{{ translations.steps.date }}</button>
            <button type="button" :disabled="!date" @click="selectTimeTab" class="btn btn-outline-primary" :class="{'active' : selectedTab == 'time'}">{{ translations.steps.time_and_tickets }}</button>
            <button v-if="!isRetail" :disabled="true" type="button" @click="selectedTab = 'confirm'" class="btn btn-outline-primary" :class="{'active' : selectedTab == 'confirm'}">{{ translations.steps.confirm }}</button>
          </div>
        </div>
      </div>
      <div class="row" v-if="selectedTab == 'date'">
        <div class="col-md-12">
          <center v-if="dateLoading">
            <i class='fa fa-spinner fa-spin'></i>
          </center>
          <date-picker v-if="!dateLoading" v-model="date" :config="options" @dp-change="dateChanged"></date-picker>
        </div>
      </div>
      <div v-if="selectedTab == 'time'">
        <div class="row pt-3">
          <!-- select time and tickets -->
          <div class="col-md-12" v-if="date">
            <center>
              <span class="badge badge-primary">{{ showDate }}</span>
              <h3>{{ translations.select_a_time }}</h3>
              <p class="text-left" v-if="instructions && instructions.length">{{ instructions }}</p>
            </center>
            <center v-if="loading">
              <i class='fa fa-spinner fa-spin'></i>
            </center>
            <select @change="selectTimeSlot" v-model="selectedTimeSlot" class="form-control custom-select">
              <option value="" v-if="selectedTimeSlot == ''">{{ translations.make_a_choice }}</option>
              <option :value="timeSlot" v-for="(timeSlot, key) in timeBlocks" :key="key" v-bind="{ disabled: timeSlot.capacity == 0 }">
                {{ timeSlotLabel(timeSlot) }}
              </option>
            </select>
          </div>
        </div>
        <div class="row pt-3" v-if="selectedTimeSlot != ''">
          <div class="col-md-12">
            <div class="mt-2">
              <DWTChosenDate :locale="locale" :translations="translations" :date="date" :timeslot="selectedTimeSlot" />
              <div v-if="!isRetail">
                <center><h3>{{ translations.select_tickets }}</h3></center>
                <center v-if="ticketsLoading">
                  <i class='fa fa-spinner fa-spin'></i>
                </center>
                <table class="table dwt-table table-striped">
                  <DWTTicketSelector @change="changeSelection" :show-capacity="showCapacity" :translations="translations" :ticket-kind-block="ticketKindBlock" v-for="(ticketKindBlock, key) in ticketKinds" :key="key" />
                </table>
              </div>
              <div class="alert alert-warning" v-if="hasOverCapacity">
                {{ translations.selected_more_than_max_capacity }}: {{ selectedTimeSlot.capacity }}
              </div>
              <div style="text-align: right;" v-if="false">
                <button :disabled="!hasCartItems || hasOverCapacity" @click="selectedTab = 'confirm'" class="btn btn-success">{{ translations.confirm }}</button>
              </div>
            </div>

            <div class="mt-2 pb-3" v-if="totalSelected > 0 && isRetail">
              <RetailConfirmationForm :pickup="pickup" :form-id="formId" :cart="cart" :date="date" :time-slot="selectedTimeSlot" :translations="translations" />
            </div>

            <div class="mt-2 pb-3" v-if="totalSelected > 0 && !isRetail">
              <center><h3>{{ translations.summery }}</h3></center>
              <DWTCart :translations="translations" :is-embedded="isEmbedded" :cart-key="cartKey" :date="date" :block-id="selectedTimeSlot.id" :admin-mode="false" :capacity-planner-id="capacityPlannerId" :form-id="formId" :items="cart" :ticket-kinds="ticketKinds" :locale="locale" />
            </div>
          </div>

          

        </div>
      </div>
      <div class="row pt-3" v-if="selectedTab == 'confirm'">
        <!-- order overview -->
        <div class="col-md-12" v-if="hasCartItems">

          <center><h3>{{ translations.summery }}</h3></center>
          <DWTChosenDate :locale="locale" :translations="translations" :date="date" :timeslot="selectedTimeSlot" />
          <DWTCart @update="sendHeight" :translations="translations" :is-embedded="isEmbedded" :cart-key="cartKey" :date="date" :block-id="selectedTimeSlot.id" :admin-mode="false" :capacity-planner-id="capacityPlannerId" :form-id="formId" :items="cart" :ticket-kinds="ticketKinds" :locale="locale" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios'
import moment from 'moment'
import DWTTicketSelector from './DWTTicketSelector.vue'
import DWTCart from './DWTCart.vue'
import DWTChosenDate from './DWTChosenDate.vue'
import RetailConfirmationForm from './RetailConfirmationForm.vue'
export default {
  props: ['capacityPlannerId', 'locale', 'formId', 'adminMode', 'formData', 'cartKey', 'authToken', 'isEmbedded', 'translations', 'ticketKindList', 'skipPriceList', 'messageId', 'showCapacity', 'isRetail', 'pickup'],
  components: {
    datePicker,
    DWTTicketSelector,
    DWTCart,
    DWTChosenDate,
    RetailConfirmationForm
  },
  data () {
    return {
      dates: [],
      selectedTab: 'date',
      date: null,
      startedBooking: false,
      isConfirmed: false,
      message: '',
      checkedDates: {},
      dateLoading: true,
      timeBlocks: [],
      loading: false,
      ticketsLoading: true,
      selectedTimeSlot: '',
      ticketKinds: [],
      instructions: '',
      cart: {}
    }
  },
  methods: {
    timeSlotLabel(timeSlot){
      if (this.showCapacity) {
        return `${timeSlot.capacity} ${this.translations.available} | ${timeSlot.name}`
      } else {
        return `${timeSlot.name}`
      }
    },
    sendHeight() {
      setTimeout(function () { 
        if (this.$refs.container) {
          const height = this.$refs.container.offsetHeight
          console.log(`height: ${height}`)

          window.parent.postMessage({
            'height': height + 30,
            'messageId': this.messageId
          }, '*');  
        }
      }.bind(this), 20)

    },
    startBooking() {
      this.startedBooking = true
      this.sendHeight()
    },
    selectTimeTab(){
      this.selectedTab = 'time'
      this.cart = {}
      this.sendHeight()
    },
    changeSelection(ticketKindId, quantity) {
      console.log(`ticket kind ${ticketKindId}: ${quantity}`)  
      let cart = {...this.cart}
      cart[ticketKindId] = quantity
      let newCart = {}
      for (var i = Object.keys(cart).length - 1; i >= 0; i--) {
        const key = Object.keys(cart)[i]
        if (cart[key] > 0) {
          newCart[key] = cart[key]
        }
      }
      this.cart = newCart
      this.sendHeight()
    },
    selectTimeSlot() {
      this.sendHeight()
      this.cart = {}
      this.ticketKinds = []
      this.ticketsLoading = true
      this.timeSlotString
      axios.post(`/partners/dwt/products.json`, {
        planning_id: this.capacityPlannerId, 
        form_id: this.formId,
        date: this.date,
        locale: this.locale,
        block_id: this.selectedTimeSlot.id
      })
      .then(response => {
        // console.log(response.data)
        this.ticketsLoading = false
        this.ticketKinds = response.data
        this.sendHeight()
        this.selectDefaultTickets()
      })
    },
    selectDefaultTickets() {
      if (this.isRetail) {
        console.log('selecting defaults')
        console.log(this.ticketKinds[0].ticket_kind.id)
        this.changeSelection(this.ticketKinds[0].ticket_kind.id, 1)
        // console.log(this.ticketKinds)
      }

    },
    getAvailableDates() {
      axios.post(`/partners/dwt/dates.json`, {planning_id: this.capacityPlannerId, form_id: this.formId})
      .then(response => {
        // console.log('response: ')
        // console.log(response.data)
        this.dateLoading = false
        this.dates = response.data
        this.sendHeight()
      })
    },
    dateChanged(){
      this.cart = {}
      this.timeBlocks = []
      this.selectedTimeSlot = ''
      this.isConfirmed = false
      this.message = ''
      this.instructions = ''
      this.loading = true
      this.selectedTab = 'time'
      axios.post(`/partners/dwt/capacity.json`, {
          locale: this.locale, 
          planning_id: this.capacityPlannerId, 
          date: this.date, 
          form_id: this.formId, 
          show_full_timeslots: this.showCapacity
        }).then((response) => {
          this.loading = false
          this.instructions = response.data.instructions
          this.timeBlocks = response.data.blocks
          this.sendHeight();
      }).catch((error) => {
        window.alert("Er is een fout opgetreden. Probeer het later opnieuw.");
      }).finally(() => {
        // TODO
      })
    }
  },
  computed: {
    totalSelected() {
      let total = 0
      const cart = this.cart
      Object.keys(cart).map(function(key, index) {
        total += cart[key]
      })
      return total
    },
    hasOverCapacity() {
      if (this.totalSelected > 0) {
        return this.totalSelected > this.selectedTimeSlot.capacity
      }
    },
    hasCartItems() {
      return Object.keys(this.cart).length > 0
    },
    showDate() {
      // let date = new Date(Date.UTC(2012, 11, 12, 3, 0, 0));
      console.log(this.date)
      const y = this.date.split("-")[0]
      const m = this.date.split("-")[1]
      const d = this.date.split("-")[2]
      return `${d}-${m}-${y}`
      // const event = Date(Date.parse(this.date))
      // console.log("date:")
      // console.log(event)
      // return event.toLocaleString('nl-NL', {dateStyle: 'short', weekday: 'long', month: 'long', day: 'numeric' })
    },
    options() {
      return {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: moment().format('YYYY-MM-DD'),
        calendarWeeks: false,
        locale: this.locale,
        enabledDates: this.dates
      }
    }
  },
  created() {
    if (this.skipPriceList) {
      this.startedBooking = true
    } 
    this.getAvailableDates()
    this.sendHeight()
  }
};
</script>

<style>
  .dwt-table.table-striped td, .dwt-table.table-striped th{
    padding-top: 4px;
    padding-bottom:4px;
    vertical-align: middle;
  }
</style>