<template>
  <div>
    <div class="row">
      <div class="col-md-12" style="text-align: center;">
        <div class="btn-group" role="group" style="margin-left: auto; margin-right: auto;">
          <button @click="selectTickets" class="btn btn-sm btn-outline-primary" :class="{'active' : currentStep == 'selectTickets'}">1. {{ translations.select_tickets }}</button>
          <button @click="selectDate" class="btn btn-sm btn-outline-primary" :disabled="totalSelected == 0" :class="{'active' : currentStep == 'selectDate'}">2. {{ translations.date_and_time }}</button>
        </div>    
      </div>
    </div>
    
    <div v-if="currentStep == 'selectTickets'">
      <div class="row">
        <div class="col-md-12">
          <table class="table">
            <GlobalTicketConsumerTicketSelector @change="changeSelection" :ticket-kind="ticketKind" v-for="(ticketKind, key) in ticketKinds" :key="key" />
          </table>    
        </div>
        <div class="col-md-12" style="text-align: right;">
          <button 
            class="btn btn-success fr float-right" 
            @click="selectDate"
            :disabled="totalSelected == 0"
          >
            {{ translations.select_a_date}}
          </button>
        </div>
      </div>
      
    </div>

    <div v-if="currentStep == 'selectDate'">
      <date-picker v-if="dates.length" v-model="date" :config="options" @dp-change="dateChanged"></date-picker>

      <div v-if="loadingTimes" style="text-align: center; margin-top: 30px; margin-bottom: 30px;" >
        <i class='fa fa-spinner fa-spin'></i> {{ translations.fetching_times }}
      </div>
      <div class="row" style="margin-top: 10px;" v-if="times.length">
        <center><h4>{{ translations.select_a_time }}</h4></center>
        <div class="col-sm-6 col-xs-6 col-md-3 col-lg-2 " style="margin-bottom: 12px;" v-for="(time, key) in times" :key="key">
          <button @click="chooseTime(time, $event)" class="btn btn-primary btn-block" :class="{'active' : time.time_with_date == dateTime}" :disabled="time.available < totalSelected">{{ time.time }}</button>
        </div>
      </div>
    </div>

    <div v-for="(key, index) in cartKeys" :key="index">
      <input type="hidden" :name="`cart[cart_items_attributes[${index}][ticket_kind_id]`" :value="key">
      <input type="hidden" :name="`cart[cart_items_attributes[${index}][quantity]`" :value="cart[key]">
      <input type="hidden" :name="`cart[cart_items_attributes[${index}][chosen_date]`" v-model="dateTime">
    </div>

    <div v-if="showSummary">
      <center>
        <h4>{{ translations.summery }}</h4>
        {{ translations.chosen_date_and_time }}: <strong>{{ dateString }}</strong>
      </center>
      <ul style="margin-top: 10px; margin-bottom: 10px;">
        <li v-for="(ticketKind, key) in ticketKinds" :key="key" v-if="cart[ticketKind.id] && cart[ticketKind.id] > 0">
          {{ cart[ticketKind.id] }} &times; {{ ticketKind.name }}
        </li>
      </ul>    
    </div>
    <div v-if="currentStep == 'selectDate' && date">
      <button type="submit" class="btn btn-success btn-block" :data-disable-with="translations.reservation_success" style="margin-top: 10px;" :disabled="!showSummary || loading">{{ translations.confirm }}</button>
    </div>

  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker'
import axios from 'axios'
import GlobalTicketConsumerTicketSelector from './GlobalTicketConsumerTicketSelector.vue'

export default {

  name: 'GlobalTicketConsumerDatePicker',
  components: { GlobalTicketConsumerTicketSelector, datePicker },
  props: ['ticketKinds', 'formId', 'cartKey', 'translations', 'locale'],
  data () {
    return {
      currentStep: 'selectTickets',
      cart: {},
      dateString: '',
      date: null,
      dateTime: null,
      dates: [],
      loadingTimes: false,
      times: [],
      loading: false,
      showSummary: false
    }
  },
  created() {
    this.fetchDates()
  }, 
  methods: {
    setLoading(){
      this.loading = true
    },
    chooseTime(time, event){
      this.dateTime = time.time_with_date
      this.showSummary = true
      this.dateString = time.date_string
      event.preventDefault()
      // window.scrollBy(0, 60)
      // setTimeout(function () { 
      //   document.getElementById('new_cart').submit()
      // }, 300)
    },
    dateChanged() {
      this.loadingTimes = true
      this.dateTime = null
      this.showSummary = false
      this.times = []
      this.dateString = ''
      axios.post('/partners/globalticket-times.json', {
        form_id: this.formId,
        date: this.date
      }).then((response) => {
        this.times = response.data
        // console.log(response)
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
        this.loadingTimes = false
      });
    },
    fetchDates() {
      axios.post('/partners/globalticket-dates.json', {
        form_id: this.formId,
      }).then((response) => {
        // TODO
        // console.log(response)
        this.dates = response.data
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });

      
    },
    selectTickets(event) {
      this.currentStep = 'selectTickets'
      this.dateTime = null
      this.date = null
      this.cart = {}
      this.showSummary = false
      this.times = []
      event.preventDefault()
    },
    selectDate(event) {
      this.currentStep = 'selectDate'
      event.preventDefault()
    },
    changeSelection(ticketKindId, quantity) {
      // console.log('changeSelection')
      let cart = {...this.cart}
      cart[ticketKindId] = quantity
      let newCart = {}
      for (var i = Object.keys(cart).length - 1; i >= 0; i--) {
        const key = Object.keys(cart)[i]
        if (cart[key] > 0) {
          newCart[key] = cart[key]
        }
      }
      this.cart = newCart
      // this.sendHeight()
    }
  },
  computed: {
    options() {
      return {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: moment().format('YYYY-MM-DD'),
        calendarWeeks: false,
        locale: this.locale,
        enabledDates: this.dates
      }
    },
    cartKeys() {
      return Object.keys(this.cart)
    },
    totalSelected() {
      let total = 0
      const cart = this.cart
      Object.keys(cart).map(function(key, index) {
        total += cart[key]
      })
      return total
    },
  }
}
</script>

<style lang="css" scoped>
</style>