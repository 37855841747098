<template>

    <div>
      <div class="row">
        <div class="col-sm-12 col-md-4" v-if="!simpleproduct">
          <center><h3>Selecteer een datum</h3></center>
          <date-picker v-model="date" :config="options" @dp-change="dateChanged"></date-picker>
        </div>
        <div class="col-sm-12 col-md-4" v-if="date">
          <center v-if="time_type == '1' && !simpleproduct">
            <h3>Selecteer een tijd</h3>
          </center>

          <select @change="selectTimeSlot()" v-model="selectedTimeSlot" class="form-control" v-if="time_type == 1 && !simpleproduct">
            <option v-if="!selectedTimeSlot" value="null">Maak een keuze...</option>
            <option :value="timeSlot" :disabled="timeSlot.Free == 0" v-for="(timeSlot, key) in blocks" :key="key" >
              {{ timeSlotLabel(timeSlot)}}
            </option>          
          </select>

          <div class="row" v-if="time_type == 2">
            <div class="col-lg-6">
              <center>
                <h3>Selecteer een duur</h3>
                <i v-if="blocks.length == 0" class="fa fa-spinner fa-spin"></i>
              </center>
              <select @change="selectTimeSlot()" v-model="selectedTimeSlot" class="form-control" v-if="blocks.length">
                <option v-if="!selectedTimeSlot" value="null">Maak een keuze...</option>
                <option :value="timeSlot" v-for="(timeSlot, key) in blocks" :key="key" >
                  {{ timeSlotLabel(timeSlot)}}
                </option>          
              </select>
            </div>  
            <div class="col-lg-6">
              <div class="" v-if="selectedTimeSlot">
                <center><h3>Kies starttijd</h3></center>
                <select @change="selectStartTime()" v-model="startTime" class="form-control">
                  <option v-if="startTime === null" value="null">Maak een keuze...</option>
                  <option :value="item.time" v-for="(item, key) in selectedTimeSlot.time_list" :key="key" >{{item.label}}</option>          
                </select>
              </div>
            </div>
          </div>
            
          <div class="" v-if="showQtyPicker">
            <center><h3>Kies aantal</h3></center>
            <table class="table">
              <tr :key="`price-${price.id_tarif}`" v-for="price in prices">
                <th v-if="price.description.length">{{price.description}}</th>
                <th v-if="!price.description.length">Standaardtarief</th>
                <td v-if="extraDiscount && extraDiscount > 0">
                  <strike>&euro; {{showPrice(price.price)}}</strike>
                  <span>&euro;&nbsp;{{showPrice(price.price - (extraDiscount/100))}}</span>
                </td>
                <td v-if="!extraDiscount && price.originalPrice">
                  <strike v-if="price.originalPrice != price.price">&euro; {{showPrice(price.originalPrice)}}</strike>
                  &euro; {{showPrice(price.price)}}
                </td>
                <td v-if="(!extraDiscount && !price.originalPrice)">
                  &euro; {{showPrice(price.price)}}
                </td>
                <td width="">
                  <select v-model="chosenTarifs[price.id_tarif]" @change="setTarif(price.id_tarif)" class="form-control" style="margin-top: 2px; margin-bottom: 2px;">
                    <option :value="num" v-for="(num, key) in numberArrayForTimeSlot" :key="key" >{{num}}</option>          
                  </select>
                </td>
              </tr>
            </table>
            
            <center class="text-center" style="margin-bottom: 30px;">
              <i v-if="loading" class="fa fa-spinner fa-spin"></i><br>
            </center>          
          </div>
        </div>

        
        <div class="col-sm-12 col-md-4" v-if="showConfirmation">
          <center><h3>Samenvatting</h3></center>
          <div class="well">
            <table class="table">
              <thead>
                <tr>
                  <th>Aantal</th>
                  <th>Product</th>
                  <th>Prijs</th>
                  <th>Totaal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="chosenTarifs[price.id_tarif] > 0" v-for="(price, key) in prices" :key="key">
                  <td>{{ chosenTarifs[price.id_tarif]}} &times;</td>
                  <td>{{price.description}}</td>
                  <td v-if="extraDiscount">
                    <strike class="text-danger">&euro;&nbsp;{{showPrice(price.price)}}</strike>
                    <strong>&euro;&nbsp;{{showPrice(price.price - (extraDiscount/100))}}</strong>
                  </td>
                  <td v-if="!extraDiscount">
                    &euro;&nbsp;{{showPrice(price.price)}}
                  </td>
                  <th>&euro;&nbsp;{{showPrice(priceToUseWithDiscount(price.price) * chosenTarifs[price.id_tarif])}}</th>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>{{totalPax()}} &times;</th>
                  <th colspan="2">Totaalbedrag</th>
                  <th>&euro; {{showPrice(totalPrice())}}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          
          <div class="alert alert-warning" role="alert" v-if="!isConfirmed">
            {{message}}
          </div>

          <br>
          <form :action="`/partners/leisure-king/carts/${cart_key}/cart_item/${cartitemid}`" accept-charset="UTF-8" method="post">
            <input name="utf8" type="hidden" value="✓">
            <input name="date" type="hidden" :value="date">
            <input name="start_time" type="hidden" :value="startTime">
            <input name="overview" type="hidden" :value="JSON.stringify(overview)">
            <input name="time_slot" type="hidden" :value="JSON.stringify(selectedTimeSlot)">
            <input name="tarifs" type="hidden" :value="JSON.stringify(chosenTarifs)">
            <input v-bind:disabled="!isConfirmed" type="submit" name="commit" value="Bevestig keuze" class="btn btn-success btn-block ga" data-disable-with="Even geduld..." data-ga-category="Winkelmand" data-ga-action="Datum Bevestigen">
          </form>
          
        </div>

        
      </div>
    </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios'
import moment from 'moment'
export default {
  props: ["product_id", "cart_key", "cartitemid", "min", "max", "time_type", "dates", "simpleproduct", "extraDiscount", "locale", "barcodeBatchId"],
  data () {
    return {
      date: null,
      images: [],
      free: 0,
      timeType: 'block',
      isConfirmed: false,
      message: '',
      blocks: [],
      prices: [],
      overview: {},
      apiOptions: {},
      checkedDates: {},
      chosenTarifs: {},
      loading: false,
      startTime: null,
      selectedTimeSlot: null,
      options: {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: moment().format('YYYY-MM-DD'),
        locale: this.locale,
        enabledDates: this.dates
      }       
    }
  },
  created() {
    this.fetchApiAptions()
    if (this.simpleproduct) {
      const today = new Date()
      const year  = today.getFullYear()
      let mm   = today.getMonth() + 1
      let dd      = today.getDate()
      if (dd<10) {
        dd = '0'+dd
      }
      if (mm<10) {
        mm = '0'+mm
      }
      this.date = `${year}-${mm}-${dd}`
      this.dateChanged()
    }
  },
  methods: {
    fetchApiAptions() {
      axios.get(`/api/barcode_batches/${this.barcodeBatchId}/options.json`)
      .then((response) => {
        this.apiOptions = response.data
      });
    },
    setTarif(id){
      this.overview = this.summary
      // console.log(`checking ${this.totalPax} < ${this.min}`)
      // console.log(`checking ${this.totalPax} > ${this.max}`)
      if (this.totalPax() < this.min){
        this.message = `Het gekozen aantal is te laag. Dit arrangement is te boeken vanaf minimaal ${this.min} personen`
        this.isConfirmed = false
      } else if (this.totalPax() > this.max) {
        this.message = `Het gekozen aantal is te hoog. Dit arrangement is te boeken voor maximaal ${this.max} personen`
        this.isConfirmed = false
        console.log("too many!")
      } else {
        this.isConfirmed = true
      }
    },
    showPrice(price){
      return parseFloat(price).toFixed(2).replace('.', ',')
    },
    dateChanged(){
      this.selectedTimeSlot = null
      this.isConfirmed = false
      this.blocks = []
      this.message = ''
      this.free = 0
      this.fetchDate()
    },
    checkPrice(){
      this.chosenTarifs = {}
      axios.get(`/partners/leisure-king/price/${this.product_id}/block/${this.selectedTimeSlot.ID_Block}?barcode_batch_id=${this.barcodeBatchId}`)
        .then(response => {
          this.loading = false
          this.prices = response.data.data
          // this.message = response.data
          this.setDefaultTarifs()
        })
        .catch(error => {
          this.loading = false
          this.message = error.response.statusText
        })
    },
    setDefaultTarifs(){
      let values = {}
      for (let index = 0; index < this.prices.length; index++) {
        let price = this.prices[index]
        values[price.id_tarif] = 0        
      }
      this.chosenTarifs = values
    },
    fetchDate(){
      this.prices = []
      axios.get(`/partners/leisure-king/product/${this.product_id}/date/${this.date}`)
        .then(response => {
            this.loading = false
            this.blocks = response.data.data
            this.message = response.data
            if (this.simpleproduct && this.blocks.length == 1) {
              this.selectedTimeSlot = this.blocks[0]
              this.selectTimeSlot()
            }


        })
        .catch(error => {
          this.loading = false
          this.message = error.response.statusText
        })
    },
    selectStartTime() {
      // https://api.leisureking.eu/v1l/assortiment/getstockdetails
      this.loading = true
      axios.get(`/partners/leisure-king/product/${this.product_id}/date/${this.date}/stock?id_block=${this.selectedTimeSlot.ID_Block}&starttime=${this.startTime}`)
        .then(response => {
            this.loading  = false
            this.free     = response.data["data"]["Free"]
            // this.message = response.data
        })
        .catch(error => {
          this.loading = false
          this.message = error.response.statusText
        })
    },
    selectTimeSlot(){
      this.startTime = null
      this.prices = []
      
      if (this.selectedTimeSlot.Free){
        this.timeType   = 'blocks'
        this.free       = this.selectedTimeSlot.Free
        // this.startTime  = this.selectedTimeSlot()
      } else {
        this.free     = 0
        this.timeType = 'custom'
      }
      // this.selectedTimeSlot = timeSlot
      this.loading = true
      this.isConfirmed = false
      this.message = "De prijzen worden opgehaald..."
      this.checkPrice()
    },
    timeToShow(timeSlot){
      const d1 = timeSlot.BlockStart.substring(0, 5)
      const d2 = timeSlot.BlockEnd.substring(0, 5)
      return `${d1} tot ${d2} uur`
    },
    totalPrice(){
      let pr = 0
      for (let index = 0; index < this.prices.length; index++) {
        const price = this.prices[index];
        pr += this.chosenTarifs[price.id_tarif] * this.priceToUseWithDiscount(price.price)
      }
      return pr
    },
    totalPax(){
      let total = 0
      for (var key in this.chosenTarifs) {
          total += this.chosenTarifs[key]
      }
      return total
    },
    timeSlotLabel(timeSlot){
      if (this.time_type == 2){
        return timeSlot.Blockname
      } else {
        return `${timeSlot.Blockname}  - ${this.timeToShow(timeSlot)} (${timeSlot.Free} beschikbaar)`
        }
    },
    priceToUseWithDiscount(price) {
      if (this.extraDiscount && this.extraDiscount > 0) {
        return price - (this.extraDiscount / 100.0)
      } else {
        return price
      }
    }
  },
  computed: {

    showQtyPicker(){
      if (this.time_type == 2 && this.startTime === null){
        return false
      } else if (this.selectedTimeSlot){
        return true
      } else {
        return false
      }
    },
    summary(){
      if (this.prices.length){
        let sum = []
        for (let index = 0; index < this.prices.length; index++) {
          const element = this.prices[index]
          if (this.chosenTarifs[element.id_tarif]){
            sum.push({
              quantity: this.chosenTarifs[element.id_tarif],
              product: element
            })
          }
        }
        return sum
      } else {
        return {}
      }
      
    },
    numberArrayForTimeSlot(){
      let num = 0
      if (this.free && this.free > 0){

        if (this.free > this.max){
            num = this.max
        } else {
          num = this.free
        }
      } else if (this.simpleproduct) {
        num = 100
      } else {
        return [0]
      }
      return Array.from(Array(num + 1).keys())
    },
    showConfirmation() {
      if (this.selectedTimeSlot && this.totalPrice() > 0){
        return true
      } else {
        return false
      }
    },
    selectedDate() {
      if ( this.date == null ) {
        return null
      } else {
        console.log(this.date)
        // return this.timeslots[this.date][1]
      }
    }
  },
  components: {
    datePicker
  }
}
</script>

<style>

</style>