<template>
	<input class="form-control" type="text" :id="`question_${question.id}`" v-model="current_answer" @blur="saveAnswer"/>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Answer',
  props: ['question', 'answer', 'cart'],
  data() {
    return {
      current_answer: ""
    }
  },
  created() {
    if(this.answer) {
      this.current_answer = this.answer.answer
    }
  },
  methods: {
    saveAnswer() {
      if(this.answer) {
        axios.post(`/carts/${this.cart}/answers.json`, { question_id: this.question.id, answer_id: this.answer.id, answer: this.current_answer })
        .then().catch(error => {
          alert('Er ging iets mis. Probeer het over enkele minuten nogmaals.')
        })
      } else {
        axios.post(`/carts/${this.cart}/answers.json`, { question_id: this.question.id, answer: this.current_answer })
        .then().catch(error => {
          alert('Er ging iets mis. Probeer het over enkele minuten nogmaals.')
        })
      }
    }
  }
};
</script>

<style lang="css" scoped>
</style>