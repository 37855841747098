<template>
	<div @click="toggleAwnser()" class="row">
		<div class="col-md-6">
			<li class="font-weigth-bold"><i class="fa fa-chevron-down mr-1 text-primary"></i>{{question.question}}</li>
			<div v-bind:class="{ 'd-none': !isOpened }">
				<p class="ml-1">{{question.answer}}</p>
			</div>
			<hr/>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Question",
		props: ["question"],
		data() {
			return {
				open: false
			}
		},
		computed: {
			isOpened() {
				return this.open
			}
		},
		methods: {
			toggleAwnser() {
				this.open = !this.open
			}
		}
	};
</script>

<style scoped>
</style>