<template>
  <div>
    <h2>{{translations.title }}</h2>
    <div v-if="theGiftcards.length">
      <table class="table">
        <thead>
          <tr>
            <th>Code</th>
            <th>Geldig tot</th>
            <th>Oorspr. waarde</th>
            <th>Huidige waarde</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(giftcard, key) in theGiftcards" :key="key">
            <td>{{ giftcard.code }}</td>
            <td>{{ giftcard.valid_until }}</td>
            <td>{{ giftcard.original_value }}</td>
            <td>{{ giftcard.current_value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!theGiftcards.length">
      <em>{{ translations.no_giftcards }}</em>
    </div>
    <br>
    <div class="alert alert-danger" v-if="error.length">
      {{ error }}
    </div>
    <div class="input-group" v-if="newField">
      <span class="input-group-addon">pasnummer</span>
      <input ref="codefield" v-on:keydown.enter="submit" v-model="code" type="text" class="form-control">
      <span class="input-group-btn">
        <button @click="submit" :disabled="disabledField" class="btn btn-primary">{{ translations.link }}</button>
      </span>
      
    </div>
    
    <button v-if="!newField" @click="focusField" class="btn btn-sm btn-primary">{{ translations.link }}</button>
    <button v-if="newField" @click="newField = false" class="btn btn-sm btn-secondary" style="margin-top: 10px;">{{ translations.cancel }}</button>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'ConsumerGiftcards',
  props: ['translations', 'giftcards'],
  data () {
    return {
      theGiftcards: [],
      newField: false,
      code: '',
      error: '',
      loading: false
    }
  },
  methods: {
    focusField() {
      this.newField = true
      setTimeout(function () { 
        this.$refs.codefield.focus()
      }.bind(this),100)
      
    },
    submit() {
      this.error = ''
      if (!this.disabledField) {
        console.log('submitting')
        axios.post(`/giftcards/link-to-member.json`, { code: this.code })
        .then(response => {
          console.log(response.data)
          this.loading = false
          this.newField = false
          if (response.data.success) {
            this.theGiftcards = response.data.giftcards
          } else {
            this.error = response.data.message
            setTimeout(function () { 
              this.error = ''
            }.bind(this),3000)
          }
        })
        .catch(error => {
          // this.saving = false
          console.log(error)
          alert('Er ging iets mis. Probeer het over enkele minuten nogmaals.')
        })
      }
    }
  },
  computed: {
    disabledField() {
      return this.loading || this.code.length != 12
    }
  },
  created() {
    this.theGiftcards = this.giftcards
  }
}
</script>

<style lang="css" scoped>
</style>