import { render, staticRenderFns } from "./DWTTicketSelector.vue?vue&type=template&id=338d0265&scoped=true&"
import script from "./DWTTicketSelector.vue?vue&type=script&lang=js&"
export * from "./DWTTicketSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "338d0265",
  null
  
)

export default component.exports