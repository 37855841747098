<template>
  <div class="form-group mb-3">
    <label>{{ ticketKind.name }} code {{ index }}</label>
    <div class="input-group" :clas="{'was-validated' : (voucherCode.valid || invalid)}">
      <input 
        ref="code"
        type="text" 
        class="form-control" 
        :class="{'is-invalid' : invalid, 'is-valid' : voucherCode.valid}"
        style="font-family: monospace; font-size: 16px; text-transform: uppercase;"
        v-model="code" 
        v-on:keydown.enter="saveValue"
        :disabled="checking"
        :readonly="voucherCode.valid"
        @keyup="resetValid"
        placeholder="Voer vouchercode in" 
      />
      <div class="input-group-append">
        <button v-if="!voucherCode.valid" @click="saveValue" class="btn btn-primary" :disabled="checking || duplicate">Toevoegen</button>
        <button v-if="voucherCode.valid" @click="clearValue" class="btn btn-secondary">Wissen</button>
      </div>
      <div class="invalid-feedback" v-if="invalid">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'DWTVoucherInput',
  props: ['ticketKindId', 'index', 'voucherCode', 'ticketKind', 'codes'],
  data () {
    return {
      code: '',
      invalid: false,
      checking: false
    }
  },
  created() {
    this.code = this.voucherCode.code
  },
  computed: {
    duplicate() {
      return false
      // return this.codes.include(this.code)
      // let occ = 0
      // for (var i = this.codes.length - 1; i >= 0; i--) {
      //   if this.codes[i] == this.code
      // }
    }
  },
  methods: {
    resetValid() {
      if (this.voucherCode.code.length == 0){
        this.invalid = false
        this.voucherCode.valid = false
      }
    },
    clearValue() {
      this.invalid = false
      this.voucherCode.code = ''
      this.code = ''
      this.voucherCode.valid = false
      this.$refs.code.focus()
    },
    saveValue() {
      this.invalid = false
      // this.voucherCode.valid = true
      this.checking = true
      axios
      axios.post(`/ticket_kinds/${this.ticketKindId}/check_voucher.json`, {
        code: this.code,
        codes: this.codes
      }).then((response) => {
        console.log(response)
        this.checking = false
        if (response.data.valid) {
          this.voucherCode.valid = true
          this.voucherCode.code = this.code
          this.$refs.code.blur()
        } else {
          this.voucherCode.valid = false
          this.message = response.data.message
          this.invalid = true
          this.$refs.code.select()
        }
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        // TODO
      });
      // this.voucherCode.valid = true
      // this.invalid = true
    }
  }
}
</script>

<style lang="css" scoped>
</style>