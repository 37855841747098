<template>
  <div class="row" style="margin-top: 18px;"> 
    <div class="col-md-6">
      <strong style="display: block; font-size: 12pt">{{ showName}}</strong>  
      <span style="font-weight: normal;" v-if="ageString">{{ ageString }}</span>  
    </div>
    <div class="col-md-3 text-right">
      <span v-if="ticket.discount_price" style="font-size: 14px; color: red; text-decoration: line-through; margin-right: 8px;">{{ formattedPrice }}</span>
      <span v-if="ticket.discount_price" class="price" style="font-weight: 700; font-size: 16pt">{{ formattedDiscount }}</span>
      <span v-if="!ticket.discount_price" class="price" style="font-weight: 700; font-size: 16pt">{{ formattedPrice }}</span>
    </div>
    <div class="col-md-3">
      <select @change="emitChanges" v-model="count" class="form-control">
        <option v-for="(num, key) in selectableNumbers" :key="key">{{ num }}</option>    
      </select>
    </div>
    <div class="col-md-12 mb-3" :key="extraOption.extra_option_id" v-for="extraOption in ticket.extra_options" v-if="count > 0 && extraOption.is_mandatory == '1'">
      <em>{{ optionName(extraOption.extra_option_name) }} <span v-if="extraOption.is_mandatory == '1'">(Verplicht)</span></em>
        <div class="row" v-if="i == 0" v-for="(option, i) in extraOption.options" :key="i">
          <div class="col-md-2">
            {{ count }} ×
          </div>
          <div class="col-md-4">
            {{ optionName(option.option_name) }}
          </div>
          <div class="col-md-3">
            {{ priceFormatter(option.option_price) }}
          </div>
        </div>

    </div>

  </div>

</template>

<script>
export default {

  name: 'PrioticketTicketSelectorTicket',
  props: ['ticket', 'maxToSelect', 'translations'],
  data () {
    return {
      count: 0,
      extraOptions: []
    }
  },
  computed: {
    ageString() {
      if (parseInt(this.ticket.age_from) == 0) {
        return `Tot en met ${this.ticket.age_to} jaar`
      } else if (parseInt(this.ticket.age_to) == 99) {
        return `Vanaf ${this.ticket.age_from} jaar`
      } else if (parseInt(this.ticket.age_to) > 0 && parseInt(this.ticket.age_from) > 0) {
        return `Van ${this.ticket.age_from} t/m ${this.ticket.age_to} jaar`
      } else {
        return false
      }
    },
    selectableNumbers() {
      return Array.from(Array(this.maxToSelect + 1).keys())
    },
    showName() {
      let params = this.ticket.ticket_type.toLowerCase().replace(/\s/g, '')
      if (this.translations['prioticket_types'][params]) {
        return this.translations['prioticket_types'][params]
      } else {
        return this.ticket.ticket_type
      }
    },
    formattedPrice() {
      return this.priceFormatter(this.ticket.unit_list_price)
    },
    formattedDiscount() {
      return this.priceFormatter(this.ticket.discount_price)
    },
    total() {
      return 300
    }
  },
  methods: {
    makeExtraOptions() {
      if (this.count == 0) {
        this.extraOptions = []
      } else {
        let opt = []
        for (var i = this.ticket.extra_options && this.ticket.extra_options.length - 1; i >= 0; i--) {
          if (this.ticket.extra_options[i].is_mandatory = "1" ) {
            let a = []
            // for (var j = this.ticket.extra_options[i].options.length - 1; j >= 0; j--) {
            let optopt = this.ticket.extra_options[i].options[0]
            a.push({
              option_id: optopt.option_id,
              count: this.count
            })
            // }
            let obj = {
              extra_option_id: this.ticket.extra_options[i].extra_option_id,
              options: a
            }
            opt.push(obj)
          }
        }
        this.extraOptions = opt
      }
    },
    emitChanges() {
      this.makeExtraOptions()
      this.$emit('change', {
        price: this.total,
        order: {
          ticket_type: this.ticket.ticket_type,
          count: this.count,
          extra_options: this.extraOptions  
        }
        
      })
    },
    optionName(name) {
      // console.log(this.translations)
      let params = name.toLowerCase().replace(/\s/g, '')
      if (this.translations['prioticket_options'][params]) {
        return this.translations['prioticket_options'][params]
      } else {
        return name
      }
    },
    priceFormatter(price) {
      return new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR'
      }).format(price)
    }
  }
}
</script>

<style lang="css" scoped>
</style>