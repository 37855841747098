<template>
  <tr>
    <td class="text-left">
      <strong>{{ ticketKindBlock.ticket_kind.name }}</strong>
      <div class="small">{{ ticketKindBlock.ticket_kind.description }}</div>
    </td>
    <td class="text-right">
      <span v-if="hasOriginalPrice" style="font-size: 14px; color: red; text-decoration: line-through; margin-right: 8px;">{{ showOriginalPrice }}</span>
      <span style="font-size: 24px; font-weight: 700;">{{ showPrice }}</span>
    </td>
    <td style="text-align: right; max-width: 100px;">
      <select @change="update" v-model="selectedValue" class="form-control custom-select" style="min-width: 54px; max-width: 100px; margin-left: auto;">
        <option :value="option" v-for="(option, key) in selectOptions" :key="key" v-if="key <= 100">{{ option }}</option>
      </select>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['ticketKindBlock'],
  name: 'TicketCounterTicketSelector',

  data () {
    return {
      selectedValue: 0
    }
  },
  computed: {
    showPrice(){
      return this.priceFormatter(this.ticketKindBlock.ticket_kind.price)
    },
    showOriginalPrice(){
      return this.priceFormatter(this.ticketKindBlock.ticket_kind.original_price)
    },
    hasOriginalPrice() {
      return this.ticketKindBlock.ticket_kind.original_price && this.ticketKindBlock.ticket_kind.original_price != this.ticketKindBlock.ticket_kind.price
    },
    selectOptions() {
      // return [4, 5, 6, 7, 8, 9, 10]
      let nums = Array(this.ticketKindBlock.capacity)
        .fill(1)
        .map((x, y) => x + y)
      nums.unshift(0)
      return nums
    }
  },

  methods: {
    update() {
      console.log('updating selection')
      this.$emit('change', this.ticketKindBlock.ticket_kind.id, this.selectedValue)
    },
    priceFormatter(price) {
      return new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR'
      }).format(price / 100.0)
    }
  }
}
</script>

<style lang="css" scoped>
</style>