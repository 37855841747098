<template>
  <div>
    <div class="row">

      <PrioticketTicketSelectorTicket @change="changeSelection" class="col-md-12 my-3" :translations="translations" :max-to-select="maxToSelect" :ticket="ticket" :key="`ticket-${i}`" v-for="(ticket,i) in tickets" />
    </div>
  </div>
</template>

<script>
import PrioticketTicketSelectorTicket from './PrioticketTicketSelectorTicket.vue'
export default {
  props: ['tickets', 'translations', 'maxToSelect'],
  name: 'PrioTicketTicketSelector',
  components: {PrioticketTicketSelectorTicket},
  data () {
    return {
      selection: {},
      totals: {}
    }
  },
  mounted() {
    this.setEmptySelection()
  },
  computed: {
    selectionArray() {
      let arr = []
      for (var i = this.tickets.length - 1; i >= 0; i--) {
        if (this.selection[this.tickets[i].ticket_type]) {
          if (parseInt(this.selection[this.tickets[i].ticket_type].count) > 0) {
            arr.push(this.selection[this.tickets[i].ticket_type])
          } 
        }
      }
      return arr
    }
  },
  methods: {
    setEmptySelection() {
      let arr = {}
      let totals = {}
      for (var i = this.tickets.length - 1; i >= 0; i--) {
        totals[this.tickets[i].ticket_type] = 0
        arr[this.tickets[i].ticket_type] = {
          ticket_type: this.tickets[i].ticket_type,
          count: 0
        }
      }
      this.totals = totals
      this.selection = arr
    },
    changeSelection(options) {
      console.log(options)
      let arr = this.selection
      let tot = this.totals
      arr[options.order.ticket_type] = options.order
      tot[options.order.ticket_type] = options.price
        // }
      // }
      this.$emit('change', this.selectionArray)
      this.selection = arr
      this.totals = tot
    }
  }
}
</script>

<style lang="css" scoped>
</style>