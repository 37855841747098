<template>
	<div>
	  <div class="form-group" :class="{'has-error' : hasError(question)}" style="margin-bottom: 0px;" v-for="(question, key) in questions" :key="key">
      <label :for="`question_${question.id}`" class="col-sm-4 control-label">{{ question.label }}<span v-if="question.required">*</span></label>
      <div class="col-sm-8">
        <Answer :question="question" :answer="answer(question)" :cart="cart"/>
      </div>
    	<div class="col-sm-6 col-sm-offset-4">
      	<span class="help-block">{{ question.hint }}</span>
    	</div>
	  </div>
	</div>
</template>

<script>
import Answer from './Answer';

export default {

  name: 'Questions',
  props: ['questions', 'answers', 'cart', 'errors'],
  components: {Answer},
  
  methods: {
    hasError(question) {
      return (this.errors && this.errors[`question_${question.id}`])
    },
  	answer(question) {
  		if(this.answers.filter(a => (a.question_id == question.id)).length) {
  			return this.answers.filter(a => (a.question_id == question.id))[0]
  		} else {
  			return null
  		}
  	}
  }
};
</script>

<style lang="css" scoped>
</style>