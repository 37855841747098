<template>
  <div class="chosen-date-block" style="background-color: #efefef; border-radius: 4px; padding: 10px; margin-top: 5px; margin-bottom: 5px;">
    <table class="table " style="margin-bottom: 0px;">
      <tbody>
        <tr>
          <th style="border-top: none;">{{ translations.table.date }}</th>
          <td style="border-top: none;">{{ localDate }}</td>
        </tr>
        <tr>
          <th>{{ translations.table.time }}</th>
          <td>{{ timeslot.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {

  name: 'DWTChosenDate',
  props: ['translations', 'date', 'timeslot', 'locale'],
  data () {
    return {

    }
  },
  computed: {
    localDate() {
      return new Date(this.date).toLocaleDateString(this.locale, 
        {
          weekday: 'long', 
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }
      )
    }
  }
}
</script>

<style lang="css" scoped>
</style>