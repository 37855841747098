<template>
  <div class="row">
    <div class="col-4" v-if="!hideOverview">
      <BackofficeCartOverview 
        class="bg-light" 
        ref="cart" 
        :cart="theCart" 
        :cart-items="theCartItems"
        :loading="generatingTickets"
      >  
      </BackofficeCartOverview>
    </div>
    <div class="col">
      <div class="row">
        <div class="col-12 col-md-8 mx-auto mb-2">
          <center><h3>Selecteer een datum</h3></center>
          <center v-if="dateLoading">
            <i class='fa fa-spinner fa-spin'></i>
          </center>
          <date-picker v-if="!dateLoading" v-model="date" :config="options" @dp-change="dateChanged"></date-picker>
        </div>
      </div>
      <div class="row text-left">
        <div class="col">
          <center v-if="loading">
            <i class='fa fa-spinner fa-spin'></i>
          </center>
          <table class="table" v-if="timeBlocks.length">
            <thead>
              <tr>
                <th>Blok</th>
                <th v-if="isAdmin">Max. capaciteit</th>
                <th v-if="isAdmin">Beschikbaar</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(block, key) in timeBlocks" :key="key">
                <td>{{ block.name }}</td>
                <td v-if="isAdmin">{{ block.original_capacity }}</td>
                <td v-if="isAdmin">{{ block.capacity }}</td>
                <td class="text-right">
                  <button @click="makeBooking(block.id)" :disabled="bookBtnDisabled(block)" class="btn btn-primary">Boeken</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackofficeCartOverview from './BackofficeCartOverview.vue'
import datePicker from 'vue-bootstrap-datetimepicker'
import axios from 'axios'
export default {

  name: 'DWTDateChanger',
  props: ['formId', 'cartId', 'capacityPlannerId', 'merchantId', 'cart', 'cartItems', 'path', 'hideOverview'],
  components: {
    datePicker,
    BackofficeCartOverview
  },
  data () {
    return {
      dates: [],
      date: null,
      loading: false,
      theCart: null,
      mailSent: false,
      sendingMail: false,
      theCartItems: null,
      dateLoading: true,
      timeBlocks: [],
      generatingTickets: false,
      makingBooking: false
    }
  },
  methods: {
    bookBtnDisabled(block) {
      if (this.makingBooking) {
        return true
      } else if (block.capacity < this.numberOfTickets && !this.isAdmin) {
        return true
      } else {
        return false
      }
    },
    makeBooking(blockId) {
      this.makingBooking = true
      this.loading = true
      this.generatingTickets = true
      axios.post(`/merchants/${this.merchantId}/carts/${this.cartId}/book_new_date.json`, 
        {
          planning_id: this.capacityPlannerId,
          block_id: blockId,
          date: this.date,
          key: this.cart.key,
          merchant_id: this.merchantId
        }
      )
      .then(response => {
        this.loading = false
        this.makingBooking = false
        this.date = null
        this.timeBlocks = []
        // console.log(this.$refs.cart)
        if (this.$refs.cart){
          this.$refs.cart.$el.classList.add("animated")
          this.$refs.cart.$el.classList.add("tada")
        }
        console.log('response: ')
        console.log(response.data)
        // location.reload()
        this.theCart = response.data.cart
        this.theCartItems = response.data.cart_items
        this.generatingTickets = false
        this.$emit('booked', response.data)
        // this.dateLoading = false
        // this.dates = response.data
      })
    },
    getAvailableDates() {
      axios.post(`/partners/dwt/dates.json`, {planning_id: this.capacityPlannerId})
      .then(response => {
        // console.log('response: ')
        // console.log(response.data)
        this.dateLoading = false
        this.dates = response.data
      })
    },
    dateChanged(){
      if (this.date){
        this.timeBlocks = []
        this.loading = true
        axios.post(`/partners/dwt/capacity.json`, {
          locale: this.locale, 
          planning_id: this.capacityPlannerId, 
          date: this.date, 
          form_id: this.formId,
          cart_id: this.cart.id,
          cart_key: this.cart.key,
        })
        .then(response => {
          this.loading = false
          this.timeBlocks = response.data.blocks
        })
      }
    }
  },
  computed: {
    numberOfTickets() {
      let num = 0
      for (var i = 0; i < this.cartItems.length; i++) {
        num += this.cartItems[i].quantity
      }
      return num
    },
    isAdmin() {
      return this.path.includes('change_date')
    },
    options() {
      return {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: moment().format('YYYY-MM-DD'),
        calendarWeeks: false,
        locale: 'nl',
        enabledDates: this.dates
      }
    }
  },
  created() {
    this.theCart = this.cart
    this.theCartItems = this.cartItems
    this.getAvailableDates()
  }
}
</script>

<style lang="css" scoped>
</style>