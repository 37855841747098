<template>
  <tr>
    <td class="text-left">
      <strong>{{ ticketKindBlock.ticket_kind.name }}</strong>
      <div class="small">{{ ticketKindBlock.ticket_kind.description }}</div>
      <div class="small" v-show="showCapacity">{{ ticketKindBlock.capacity }} {{ translations.available }}</div>
      <div v-if="ticketKindBlock.ticket_kind.promotion" class="small animated tada"><span style="font-weight: 700; background-color: yellow; padding: 2px 10px; box-shadow: 0px 0px 1px #dedede; ">{{ ticketKindBlock.ticket_kind.promotion.name }}</span></div>
    </td>
    <td class="text-right" style="vertical-align: middle; min-width: 90px; padding-right: 10px;">
      <span v-if="hasOriginalPrice" style="font-size: 14px; color: red; text-decoration: line-through; margin-right: 8px;">{{ ticketKindBlock.ticket_kind.original_price }}</span>
      <span style="font-size: 17px; font-weight: 700; white-space: nowrap;">{{ ticketKindBlock.ticket_kind.price }}</span>
    </td>
    <td style="text-align: right; max-width: 100px; vertical-align: middle;">
      <select @change="update" v-model="selectedValue" class="form-control custom-select" style="min-width: 54px; max-width: 100px; margin-left: auto;">
        <option :value="option" v-for="(option, key) in selectOptions" :key="key">{{ option }}</option>
      </select>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['ticketKindBlock', 'showCapacity', 'translations'],
  name: 'DWTTicketSelector',

  data () {
    return {
      selectedValue: 0
    }
  },
  computed: {
    hasOriginalPrice() {
      return this.ticketKindBlock.ticket_kind.original_price && this.ticketKindBlock.ticket_kind.original_price != this.ticketKindBlock.ticket_kind.price
    },
    selectOptions() {
      // return [4, 5, 6, 7, 8, 9, 10]
      let nums = Array(this.ticketKindBlock.capacity)
        .fill(1)
        .map((x, y) => x + y)
      nums.unshift(0)
      return nums
    }
  },
  methods: {
    update() {
      console.log('updating selection')
      this.$emit('change', this.ticketKindBlock.ticket_kind.id, this.selectedValue)
    }
  }
}
</script>

<style lang="css" scoped>
</style>