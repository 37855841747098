<template>
  <div>
    <div v-if="!startedBooking">
      <table class="table table">
        <tr v-for="(ticketKind, key) in ticketKinds.reverse()" :key="key" v-if="!ticketKind.should_auto_choose_quantity" style="border-bottom: 1px solid #333;">
          <td>
            <h4 class="form-ticket-kind-name" style="font-weight: bold; margin-bottom: 2px;">{{ticketKind.name}}</h4>
            <p class="form-ticket-kind-description" >{{ ticketKind.description }}</p>
            <span v-if="ticketKind.gift_description && ticketKind.gift_description.length" class="form-ticket-kind-label label label-default product-label" style="margin-top: -5px; margin-bottom: 5px; display: inline-block;">{{ ticketKind.gift_description }}</span>
          </td>          
          <td style="text-align: right;">
            <del v-if="ticketKind.original_price" class="form-ticket-kind-original old-price">{{ ticketKind.original_price_string }} </del>
          </td>
          <td style="text-align: right;">
            <span class="form-ticket-kind-price ticket-price">{{ ticketKind.price_string }}</span>
          </td>
        </tr>
      </table>
      <div style="text-align: right;">
        <p class="small">{{ translations.reservation_required }}</p>
        <button @click="startedBooking = true" class="btn btn-success">{{ translations.select_a_date }}</button>
      </div>
    </div>
    <div v-if="startedBooking">
      <div class="row">
        <div class="col-md-12" style="text-align: center;">
          <div class="btn-group" role="group">
            <button type="button" @click="selectedTab = 'date'" class="btn btn-outline-primary" :class="{'active' : selectedTab == 'date'}">{{ translations.steps.date }}</button>
            <button type="button" :disabled="!date" @click="selectTimeTab" class="btn btn-outline-primary" :class="{'active' : selectedTab == 'time'}">{{ translations.steps.time_and_tickets }}</button>
            <button :disabled="!hasCartItems" type="button" @click="selectedTab = 'confirm'" class="btn btn-outline-primary" :class="{'active' : selectedTab == 'confirm'}">{{ translations.steps.confirm }}</button>
          </div>
        </div>
      </div>
      <div class="row" v-if="selectedTab == 'date'">
        <div class="col-md-12">
          <center v-if="dateLoading">
            <i class='fa fa-spinner fa-spin'></i>
          </center>
          <date-picker v-if="!dateLoading" v-model="date" :config="options" @dp-change="dateChanged"></date-picker>
        </div>
      </div>
      <div v-if="selectedTab == 'time'">
        <div class="row pt-3">
          <!-- select time and tickets -->
          <div class="col-md-12" v-if="date">
            <center>
              <h3>{{ translations.select_a_time }}</h3>
              <p class="text-left" v-if="instructions && instructions.length">{{ instructions }}</p>
            </center>
            <center v-if="loading">
              <i class='fa fa-spinner fa-spin'></i>
            </center>
            <select @change="selectTimeSlot" v-model="selectedTimeSlot" class="form-control custom-select">
              <option value="" v-if="selectedTimeSlot == ''">{{ translations.make_a_choice }}</option>
              <option :value="timeSlot" v-for="(timeSlot, key) in timeBlocks" :key="key">
                {{ timeSlot.name }} ({{timeSlot.capacity}} {{translations.available}})
              </option>
            </select>
          </div>
        </div>
        <div class="row pt-3" v-if="selectedTimeSlot != ''">
          <div class="col-md-12">
            <div class="mt-2">
              <DWTChosenDate :translations="translations" :date="date" :timeslot="selectedTimeSlot" />
              <center><h3>{{ translations.select_tickets }}</h3></center>
              <center v-if="ticketsLoading">
                <i class='fa fa-spinner fa-spin'></i>
              </center>
              <table class="table dwt-table table-striped">
                <TicketCounterTicketSelector @change="changeSelection" v-if="!ticketKindBlock.ticket_kind.should_auto_choose_quantity" :ticket-kind-block="ticketKindBlock" v-for="(ticketKindBlock, key) in ticketBlocks" :key="key" />
              </table>
              <div class="alert alert-warning" v-if="hasOverCapacity">
                {{ translations.selected_more_than_max_capacity }}: {{ selectedTimeSlot.capacity }}
              </div>
              <center v-if="hasCartItems"><h3>{{ translations.summery }}</h3></center>
              <TicketCounterCart v-if="hasCartItems" :cart-key="cartKey" :date="date" :selected-time-slot="selectedTimeSlot" :form-id="formId" :items="cart" :ticket-kinds="ticketBlocks"/>

            </div>
          </div>
        </div>
      </div>
      <div class="row pt-3" v-if="selectedTab == 'confirm'">
        <!-- order overview -->
        <div class="col-md-12" v-if="hasCartItems">

          <center><h3>{{ translations.summery }}</h3></center>
          <DWTChosenDate :translations="translations" :date="date" :timeslot="selectedTimeSlot" />
          <TicketCounterCart :cart-key="cartKey" :date="date" :selected-time-slot="selectedTimeSlot" :form-id="formId" :items="cart" :ticket-kinds="ticketBlocks"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios'
import moment from 'moment'
import TicketCounterTicketSelector from './TicketCounterTicketSelector.vue'
import TicketCounterCart from './TicketCounterCart.vue'
import DWTChosenDate from './DWTChosenDate.vue'
export default {
  props: ['locale', 'mappedTicketKinds', 'ticketKinds', 'formId', 'adminMode', 'formData', 'cartKey', 'authToken', 'isEmbedded', 'translations'],
  components: {
    datePicker,
    TicketCounterTicketSelector,
    TicketCounterCart,
    DWTChosenDate
  },
  data () {
    return {
      dates: [],
      startedBooking: false,
      selectedTab: 'date',
      date: null,
      isConfirmed: false,
      message: '',
      checkedDates: {},
      dateLoading: true,
      timeBlocks: [],
      loading: false,
      ticketsLoading: false,
      selectedTimeSlot: '',
      // ticketKinds: [],
      instructions: '',
      cart: {}
    }
  },
  methods: {
    selectTimeTab(){
      this.selectedTab = 'time'
      this.cart = {}
    },
    changeSelection(ticketKindId, quantity) {
      console.log(`ticket kind ${ticketKindId}: ${quantity}`)  
      let cart = {...this.cart}
      cart[ticketKindId] = quantity
      let newCart = {}
      for (var i = Object.keys(cart).length - 1; i >= 0; i--) {
        const key = Object.keys(cart)[i]
        if (cart[key] > 0) {
          newCart[key] = cart[key]
        }
      }
      this.cart = newCart
      this.setAutoQuantity()
    },
    setAutoQuantity() {
      const ticketKinds = this.mappedTicketKinds
      let cart = {...this.cart}
      // console.log(this.cart)
      // console.log(this.ticketKinds)
      let total = 0
      Object.keys(this.cart).map(function(key, index) {
        const tk = ticketKinds[key]
        if (tk.should_auto_choose_quantity === false) {
          total += cart[key]
        }
      })
      // console.log(`total: ${total}`)
      for (var i = this.ticketKinds.length - 1; i >= 0; i--) {
        const tk = this.ticketKinds[i]
        if (tk.should_auto_choose_quantity) {
          this.cart[tk.id] = total
        }
      }

    },
    selectTimeSlot() {
      this.cart = {}
      this.ticketBlocks = []
      for (var i = this.ticketKinds.length - 1; i >= 0; i--) {
        const tk = this.ticketKinds[i]
        // console.log(`checking ${tk.identifier}`)
        // console.log(this.selectedTimeSlot.price_keys)
        if (this.selectedTimeSlot.price_keys[tk.identifier] != undefined) {
          // console.log('pushing ticketkind')
          // console.log(tk)
          this.ticketBlocks.push({
            capacity: this.selectedTimeSlot.capacity, 
            ticket_kind: tk
          })
        } else if (tk.force_show) {
          // console.log('force show')
          this.ticketBlocks.push({
            capacity: this.selectedTimeSlot.capacity, 
            ticket_kind: tk
          })
        }
      }
      // console.log(this.selectedTimeSlot.price_keys[""])
      console.log('blocks')
      console.log(this.ticketBlocks)
      // this.ticketKinds = []
      // this.ticketsLoading = true
      // this.timeSlotString
      // axios.post(`/partners/dwt/products.json`, {
      //   planning_id: this.capacityPlannerId, 
      //   form_id: this.formId,
      //   date: this.date,
      //   block_id: this.selectedTimeSlot.id
      // })
      // .then(response => {
      //   console.log(response.data)
      //   this.ticketsLoading = false
      //   this.ticketKinds = response.data
      // })
    },
    getAvailableDates() {
      axios.post(`/partners/ticket_counter/dates.json`, { form_id: this.formId})
      .then(response => {
        console.log('response: ')
        console.log(response.data)
        this.dateLoading = false
        this.dates = response.data
      })
    },
    dateChanged(){
      this.cart = {}
      this.timeBlocks = []
      this.selectedTimeSlot = ''
      this.isConfirmed = false
      this.message = ''
      this.instructions = ''
      this.loading = true
      this.selectedTab = 'time'
      axios.post(`/partners/ticket_counter/capacity.json`, {date: this.date, form_id: this.formId})
        .then(response => {
          console.log('response: ')
          console.log(response.data)
          this.loading = false
          // this.instructions = response.data.instructions
          this.timeBlocks = response.data
        })
      // this.isConfirmed = false
      // this.message = "De beschikbaarheid wordt gecontroleerd..."
        // this.checkEnvisoCapacity()  
    }
  },
  computed: {
    totalSelected() {
      let total = 0
      const cart = this.cart
      const ticketKinds = this.mappedTicketKinds
      Object.keys(cart).map(function(key, index) {
        const tk = ticketKinds[key]
        if (tk.should_auto_choose_quantity === false) {
          total += cart[key]
        }
      })
      return total
    },
    hasOverCapacity() {
      if (this.totalSelected > 0) {
        return this.totalSelected > this.selectedTimeSlot.capacity
      }
    },
    hasCartItems() {
      return Object.keys(this.cart).length > 0
    },
    showDate() {
      const event = Date.parse(this.date)
      return Date.parse(this.date).toLocaleString('nl-NL', {weekday: 'long', month: 'long', day: 'numeric' })
    },
    options() {
      return {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: moment().format('YYYY-MM-DD'),
        calendarWeeks: false,
        locale: this.locale,
        enabledDates: this.dates
      }
    }
  },
  created() {
    this.getAvailableDates()
  }
}
</script>

<style>
  .dwt-table.table-striped td, .dwt-table.table-striped th{
    padding-top: 4px;
    padding-bottom:4px;
    vertical-align: middle;
  }
</style>