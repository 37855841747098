<template>
	<ol class="breadcrumb">
    <li v-for="(item, key) in items" :key="key">
    	<a v-if="item.url" :href="item.url">
    		{{ item.text }}
    	</a>
    	<span v-else>{{item.text}}</span>
    </li>
  </ol>
</template>

<script>
	export default {
		name: "Breadcrumb",
		props: ['items']
	};
</script>