<template>
  <div class="article_outline row">
    <div class="col-md-3 col-sm-12" v-for="(article, index) in articles" :key="index">
      <div class="article_card text-center">
        <div class="header_tag" :style="getTag(article.tag_id).color ? 'background-color :' + getTag(article.tag_id).color : 'background-color:magenta'">
          {{ getTag(article.tag_id).name }}
        </div>
        <div class="article_card_body">
          <a class="article_card_body_image-container" :href="`/artikelen/${article.slug}`">
            <picture>
              <source :srcset="article.thumb_image[0]" type="image/webp" width="300" height="200" >
              <source :srcset="article.thumb_image[1]" type="image/png" width="300" height="200" >
              <img :src="article.thumb_image[1]" :alt="article.title">
            </picture>
          </a>
          <h2>{{ article.title }}</h2>
          <p>{{ article.short_description | truncate(length = 200) }}</p>
        </div>
          <div class="footer">
            <a :href="`/artikelen/${article.slug}`" class="btn btn-primary" style="width: 95%;">Lees meer</a>
            <div class="footer_date text-muted bg-light">
              {{ time_checked(article.last_checked_at) }}
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ArticleShopIndex",
    props: {
      articles: {
        type: Array,
        default: null,
        required: true
      },
      tags: {
        type: Array,
        default: null,
        required: true
      }
    },
    data() {
      return {
        checkMobile: window.innerWidth
      }
    },

    filters: {
      truncate(string, length = 0){
        if(string){
          return string.length > length ? string.slice(0, length) + "..." : string
        }
      }
    },

    methods: {
      getTag(id) {
        for (var i = 0; i < this.tags.length; i++) {
          if(this.tags[i].id == id) {
            return this.tags[i];
          }
        }
      },

      redirect(article) {
        window.location.replace(window.location.href + (window.location.href.slice(-1) == '/' ? '' : '/') + article.slug);
      },

      checkMobileVue() {
        this.checkMobile = window.innerWidth;
      },

      time_checked(last_checked_article) {
        var date = new Date(last_checked_article)
        let last_checked = Math.round((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
        if(last_checked == 0) {
          last_checked = "Vandaag"
        }
        else if(last_checked == 1) {
          last_checked += " dag geleden"
        }
        else if(last_checked > 20) {
          last_checked = `${date.getUTCDate()}-${date.getMonth()}-${date.getFullYear()}`
        }
        else {
          last_checked += " dagen geleden"
        }
        return last_checked;
      }
    },

    created() {
      window.addEventListener('resize', this.checkMobileVue);
    }
  }
</script>