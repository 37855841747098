<template>

    <div class="row">
      <div class="col-sm-12 col-md-4">
        <center><h3>Selecteer een datum</h3></center>
        <date-picker v-model="date" :config="options" @dp-change="dateChanged"></date-picker>
      </div>
      <div class="col-sm-12 col-md-4" v-if="date && capacity_type == 'per_slot'">
        <center><h3>Selecteer een tijd</h3></center>
        <button @click="selectTimeSlot(timeSlot)" v-bind:class="{'active' : timeSlot == selectedTimeSlot }" v-for="(timeSlot, key) in selectedDate" class="btn btn-success btn-block" style="maring-bottom: 10px;" :key="key">
          {{ timeSlotDate(timeSlot)}}
        </button>
      </div>
      <div class="col-sm-12 col-md-4" v-if="showConfirmation">
        <center><h3>Bevestig</h3></center>
        <center class="text-center" style="margin-bottom: 30px;">
          <i v-if="loading" class="fa fa-spinner fa-spin"></i><br>
          <span v-bind:class="{'text-success' : isConfirmed }">{{message}}</span>
        </center>
        <form :action="`/partners/enviso/carts/${cart_key}/cart_item/${cartitemid}`" accept-charset="UTF-8" method="post">
          <input name="utf8" type="hidden" value="✓">
          <input v-bind:disabled="!isConfirmed" type="submit" name="commit" value="Bevestig keuze" class="btn btn-success btn-block ga" data-disable-with="Even geduld..." data-ga-category="Winkelmand" data-ga-action="Datum Bevestigen">
          <input name="date" type="hidden" :value="date" v-if="capacity_type != 'per_slot'">
          <input name="date" type="hidden" :value="selectedTimeSlot[1]" v-if="capacity_type == 'per_slot'">
          <input name="time_slot_id" type="hidden" :value="selectedTimeSlot[0]" v-if="capacity_type == 'per_slot'">
        </form>
        
      </div>

      
    </div>

</template>

<script>
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from 'axios'
import moment from 'moment'
export default {
  props: ["dates", "timeslots", "cartitemid", "cart_key", "ticket_kind_id", "quantity", "capacity_type"],
  data () {
    return {
      date: null,
      isConfirmed: false,
      message: '',
      checkedDates: {},
      loading: false,
      selectedTimeSlot: null,
      options: {
        format: 'YYYY-MM-DD',
        inline: true,
        useCurrent: true,
        minDate: moment().format('YYYY-MM-DD'),
        enabledDates: this.dates
      }       
    }
  },
  methods: {
    dateChanged(){
      this.selectedTimeSlot = null
      this.isConfirmed = false
      this.message = ''
      if (this.capacity_type != 'per_slot'){
        this.loading = true
        this.isConfirmed = false
        this.message = "De beschikbaarheid wordt gecontroleerd..."
        this.checkEnvisoCapacity()  
      }
    },
    selectTimeSlot(timeSlot){
      this.selectedTimeSlot = timeSlot
      this.loading = true
      this.isConfirmed = false
      this.message = "De beschikbaarheid wordt gecontroleerd..."
      this.checkEnvisoCapacity()
    },
    checkEnvisoCapacity(){
      let url = ''
      let key = ''
      if (this.capacity_type == 'per_slot') {
        key = `${this.date}_${this.selectedTimeSlot[0]}`
        url = `/partners/envsiso/capacity/${this.ticket_kind_id}/0?date=${this.date}&time_slot_id=${this.selectedTimeSlot[0]}`
      } else {
        key = `${this.date}`
        url = `/partners/envsiso/capacity/${this.ticket_kind_id}/0?date=${this.date}`
      }
      // check local list first
      if (this.checkedDates[key] !== undefined){
        // blurring for a very short time
        this.loading = true
        this.message = ''
        setTimeout(function () { 
          console.log("already checked with API. Now returning the same result")
          this.loading = false
          if (this.checkedDates[key] == true){
            this.message = "Er zijn voldoende beschikbare kaarten op de gekozen tijd!"
            this.isConfirmed = true
          } else {
            this.message = "Er zijn onvoldoende beschikbare kaarten op de gekozen tijd. Kies een andere dag of tijd."
            this.isConfirmed = false
          }
        }.bind(this), 200)
        
      } else {
        // console.log(`calling ${url}`)
        axios.get(url)
          .then(response => {
            this.loading = false
            console.log(response.data)
            if ((response.data.allocatedQuantity == -1) || (response.data.allocatedQuantity > parseInt(this.quantity))) {
              this.checkedDates[key] = true
              this.message = "Er zijn voldoende beschikbare kaarten op de gekozen tijd!"
              this.isConfirmed = true
            } else {
              this.checkedDates[key] = false
              this.message = "Er zijn onvoldoende beschikbare kaarten op de gekozen tijd. Kies een andere dag of tijd."
              this.isConfirmed = false
            }
            
          })
          .catch(error => {
            this.loading = false
            this.isConfirmed = false
            console.log("an error occured...")
            if (error.response.status == 429){
              this.message = "Je hebt teveel pogingen gedaan, probeer het over enkele ogenblikken nog eens..."
            } else {
              this.message = error.response.statusText
            }
          })
      }
    },
    timeSlotDate(timeSlot){
      let d1 = timeSlot[1].substring(11, 16)
      let d2 = timeSlot[2].substring(11, 16)
      return `${d1} tot ${d2} uur`
    },
  },
  computed: {
    showConfirmation() {
      if (this.selectedTimeSlot){
        return true
      } else if (this.date && this.capacity_type != 'per_slot') {
        return true
      } else {
        return false
      }
    },
    selectedDate() {
      if (this.date == null || this.timeslots === {} || this.timeslots[this.date] == undefined) {
        return null
      } else {
        console.log(this.timeslots)
        return this.timeslots[this.date][1]
      }
    }
  },
  components: {
    datePicker
  }
}
</script>

<style>

</style>