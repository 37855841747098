<template>
  <div>
    <table class="table">
      <tbody>
        <tr v-if="!tableRow.isReservation" v-for="(tableRow, key) in tableRows" :key="key">
          <th class="text-left px-0">{{ tableRow.name }}</th>
          <td class="text-right px-0">{{ tableRow.price }}</td>
          <td class="text-right px-0">&times;&nbsp;{{ tableRow.quantity }}</td>
          <th class="text-right px-0">{{ tableRow.total }}</th>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2" class="text-left px-0">Totaal</td>
          <td class="text-right px-0">&times;&nbsp;{{ totalTickets }}</td>
          <th class="text-right px-0">{{ formattedTotal }}</th>
        </tr>
      </tfoot>
    </table>

    <div class="col-12">
      <div class="alert alert-danger" v-if="isInvalid">
        {{ message }}
      </div>
      <div class="alert alert-success" v-if="isValid">
        {{ message }}
      </div>
    </div>
    
    <div v-if="checking" class="col-12 text-center mb-2">
      <i class="fa fa-spinner fa-spin"></i> <em>de beschikbaarheid wordt gecontroleerd</em>
    </div>

    <div class="col-12 text-right">
      <div @click="makeReservation" class="btn btn-success btn-block" :disabled="checking">Tevreden? Reserveren!</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {

  name: 'TicketCounterCart',
  props: ['items', 'selectedTimeSlot', 'ticketKinds', 'capacityPlannerId', 'formId', 'date', 'cartKey'],
  watch: {
    items: {
      handler: function(oldVal, newVal) {
        // console.log('items changed!')
        this.isInvalid = false
      }
    }
  },
  data () {
    return {
      checking: false,
      firstName: '',
      lastName: '',
      email: '',
      comment: '',
      isInvalid: false,
      isValid: false,
      reservationType: null,
      message: ''

    }
  },
  computed: {
    hasMissingFields() {
      return (this.reservationType == null  || (this.firstName == '' || this.lastName == '') && this.comment == '')
    },
    total() {
      let tot = 0
      for (var i = Object.keys(this.items).length - 1; i >= 0; i--) {
        var tkid = parseInt(Object.keys(this.items)[i])
        for (var j = this.ticketKinds.length - 1; j >= 0; j--) {
          if (parseInt(this.ticketKinds[j].ticket_kind.id) == tkid) {
            if (this.ticketKinds[j].ticket_kind.should_auto_choose_quantity === false) {
              tot += this.ticketKinds[j].ticket_kind.price*this.items[tkid]
            }
          }
        }
      }
      return tot
    },
    totalTickets() {
      let tot = 0
      for (var i = Object.keys(this.items).length - 1; i >= 0; i--) {
        var tkid = parseInt(Object.keys(this.items)[i])
        for (var j = this.ticketKinds.length - 1; j >= 0; j--) {
          if (parseInt(this.ticketKinds[j].ticket_kind.id) == tkid) {
            if (this.ticketKinds[j].ticket_kind.should_auto_choose_quantity === false) {
              tot += this.items[tkid]
            }
          }
        }
      }
      return tot
    },
    formattedTotal() {
      return this.priceFormatter(this.total)
    },
    tableRows() {
      let rows = []
      for (var i = Object.keys(this.items).length - 1; i >= 0; i--) {
        var tkid = parseInt(Object.keys(this.items)[i])
        // console.log(`checking ${tkid}`)
        // get ticket kind
        for (var j = this.ticketKinds.length - 1; j >= 0; j--) {
          // console.log(`checking if ${this.ticketKinds[j].ticket_kind.id} === ${tkid}`)
          if (parseInt(this.ticketKinds[j].ticket_kind.id) == tkid) {
            rows.unshift({
              price: this.priceFormatter(this.ticketKinds[j].ticket_kind.price),
              name: this.ticketKinds[j].ticket_kind.name,
              quantity: this.items[tkid],
              isReservation: this.ticketKinds[j].ticket_kind.should_auto_choose_quantity,
              total: this.priceFormatter(this.ticketKinds[j].ticket_kind.price*this.items[tkid])
            })  

          }
        }
      }
      return rows
    }
  },
  methods: {
    makeReservation() {
      this.checking = true
      return axios.post(`/partners/ticket_counter/reservation.json`, { 
          form_id: this.formId, 
          items: this.items, 
          date: this.date,
          cart_key: this.cartKey,
          is_embedded: this.isEmbedded,
          start_time_minutes_after_midnight: this.selectedTimeSlot.start_time_minutes_after_midnight,
          time_slot_name: this.selectedTimeSlot.name
        })
        .then(response => {
          this.checking = false
          this.message  =  response.data.message
          if (response.data.valid) {
            this.isInvalid = false
            this.isValid = true
            this.message = response.data.message
          } else {
            this.isInvalid  = true
          }
          if (response.data.redirect_url) {
            this.checking = true
            setTimeout(function () { 
              console.log("redirecting")
              window.location.href = response.data.redirect_url
            }.bind(this), 300)
          }
        })
        .catch(error => {
          console.log(error)
        })

    },
    priceFormatter(price) {
      return new Intl.NumberFormat('nl', {
        style: 'currency',
        currency: 'EUR'
      }).format(price / 100.0)
    }
  }
}
</script>

<style lang="css" scoped>

</style>