<template>
	<div class="bg-white rounded">
		<div class="row">
			<div class="col-md-5 col-sm-12 col-xs-12">
				<img class="giftcard-image" src="https://dagjeweg-tickets.s3.amazonaws.com/uploaded-assets/2016/06/22/13/54/43/8069b789-b7e7-463a-ad33-53d4ea5bf57c/pasje.png">
				<span class="giftcard-header">Cadeaukaart tegoed bekijken</span>
			</div>
			<div class="col-md-7 col-sm-12 col-xs-12">
				<div class="float-right d-inline-block">
					<span class="giftcard-label">Cadeaukaart nummer</span>
					<input class="giftcard-code" v-model="code" name="code"/>
					<button @click="check" class="giftcard-submit btn btn-primary">Tegoed bekijken</button>
				</div>
			</div>
		</div>
		<div class="row" v-if="giftcard.message">
			<div class="col-md-12 col-sm-12 col-xs-12">
				<div class="alert" v-bind:class="{ 'alert-success': giftcard.valid, 'alert-danger': !giftcard.valid }">
					<center><span>{{giftcard.message}}</span></center>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
	export default {
		name: "BalanceCheck",
		data() {
			return {
				code: null,
				giftcard: {
					message: null,
					valid: null
				}
			}
		},
		methods: {
			check() {
        axios.post(`/cadeaukaart/controleren.json?code=${this.code}`).then(response => {
        	console.log(response)
        	this.giftcard = response.data
        })
			}
		}
	};
</script>