<template>
	<div class="container">
	  <div class="row margin-top-50">
	  	<div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
	    	<Breadcrumb :items="items" />
	  	</div>
	  </div>
	  <div class="row mt-3">
	    <div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
	      <h1 class="h1 font-weight-bold">Cadeaukaarten</h1>
	    </div>
	  </div>
	  <div v-if="false" class="row mt-5 desktop-usps">
	  	<div class="col-md-10 col-md-offset-2 col-sm-12 col-xs-12">
	  		<div class="row">
		  		<div v-for="(usp, key) in usps" :key="key" class="col-md-2 ">
	        	<i class='fa fa-check fa-2x text-success d-block center'/>
	        	<span class="d-block center font-weight-bold" v-html="filter_link_to_home(usp)"></span>
	      	</div>
	  		</div>
	  	</div>
	  </div>
	  <div v-if="false" class="row mt-5">
	  	<div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
	  		<div class="product-row">
	  			<Product v-for="(product, key) in products" :key="key" :product="product" class="product-col" />
	  		</div>
	  	</div>
	  </div>
	  <div class="row mt-5 mobile-usps">
	  	<div class="col-md-8 col-md-offset-2 col-sm-12 col-xs-12">
	  		<div class="row">
		  		<div v-for="(usp, key) in usps" :key="key" class="col-md-3">
	        	<i class='fa fa-check fa-2x text-success d-block center'/>
	        	<span class="d-block center font-weight-bold" v-html="filter_link_to_home(usp)"></span>
	      	</div>
	  		</div>
	  	</div>
	  </div>
	  <div class="row mt-4">
	  	<div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
	  		<BalanceCheck />
	  	</div>
	  </div>
	  <div class="row mt-4 mb-4">
	  	<div class="col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
	  		<FAQ :questions="faq" />
	  	</div>
	  </div>
	</div>
</template>

<script>
import Product from './Product.vue' 
import Breadcrumb from './Breadcrumb.vue'
import BalanceCheck from './BalanceCheck.vue' 
import FAQ from './FAQ.vue'

	export default {
		name: "Index",
		props: ["faq"],
		components: {
			Breadcrumb,
			Product,
			BalanceCheck,
			FAQ
		},
		data() {
			return {
				items: [{text: "DagjeWeg.Tickets", url: "/"}, {text: "Cadeaukaarten"}],
				usps: ["Direct per e-mail of snel per post", "5 jaar geldig", "Keuze uit een heel ruim aanbod", "Origineel en persoonlijk cadeau", "Geldig voor de uitstapjes op DagjeWeg.NL Tickets"],
				products: [
				{title: "Digitale cadeaukaart", color: "#C2DDF1", url: "/cadeaukaart/digitaal", image: "/images/cadeaukaart.png", items: ["Vanaf €5,-", "Keuze uit verschillende ontwerpen", "Met een persoonlijk bericht", "Direct verstuurd per e-mail", "5 jaar geldig", "Alleen geldig voor de uitstapjes op DagjeWeg.NL Tickets"], button_text: "Bestel een digitale cadeaukaart"}, 
				{title: "Cadeaukaart", color: "#C2DDF1", url: "/cadeaukaart/fysiek", image: "/images/cadeaukaart.jpg", items: ["Vanaf €10,-", "Gratis verzending vanaf een waarde van € 25,-", "Voor 16:30 besteld, dezelfde dag verzonden", "5 jaar geldig", "Alleen geldig voor de uitstapjes op DagjeWeg.NL Tickets"], button_text: "Bestel een fysieke cadeaukaart"}, 
				{title: "Bestellen vanaf €2500", color: "#C2DDF1", url: "/cadeaukaart/zakelijk", image: "/images/zakelijkecadeaukaart.jpg", items: ["Vanaf € 2500,-", "Voor zakelijke klanten die veel cadeaukaarten willen bestellen", "Maatwerk is mogelijk"], button_text: "Bestel zakelijke cadeaukaarten"}]
			}
		},

		methods: {
			filter_link_to_home(usp){
				if(usp.includes("DagjeWeg.NL Tickets")){
					return usp.replace("DagjeWeg.NL Tickets", "<a class='card-link' href='/'>DagjeWeg.NL Tickets</a>")
				}else{
					return usp
				}
			}
		}
	};
</script>
