<template>
	<svg v-tooltip.top-left="text" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16pt" height="16pt" viewBox="0 0 16 16" version="1.1">
		<g id="surface1">
			<path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 15.75 8 C 15.75 12.28125 12.28125 15.75 8 15.75 C 3.71875 15.75 0.25 12.28125 0.25 8 C 0.25 3.722656 3.71875 0.25 8 0.25 C 12.28125 0.25 15.75 3.722656 15.75 8 Z M 8.207031 2.8125 C 6.503906 2.8125 5.417969 3.53125 4.566406 4.804688 C 4.457031 4.96875 4.492188 5.191406 4.652344 5.3125 L 5.734375 6.136719 C 5.898438 6.257812 6.128906 6.230469 6.253906 6.070312 C 6.8125 5.359375 7.195312 4.949219 8.046875 4.949219 C 8.683594 4.949219 9.472656 5.359375 9.472656 5.980469 C 9.472656 6.449219 9.089844 6.6875 8.457031 7.042969 C 7.722656 7.453125 6.75 7.96875 6.75 9.25 L 6.75 9.375 C 6.75 9.582031 6.917969 9.75 7.125 9.75 L 8.875 9.75 C 9.082031 9.75 9.25 9.582031 9.25 9.375 L 9.25 9.332031 C 9.25 8.445312 11.847656 8.40625 11.847656 6 C 11.847656 4.1875 9.96875 2.8125 8.207031 2.8125 Z M 8 10.5625 C 7.207031 10.5625 6.5625 11.207031 6.5625 12 C 6.5625 12.792969 7.207031 13.4375 8 13.4375 C 8.792969 13.4375 9.4375 12.792969 9.4375 12 C 9.4375 11.207031 8.792969 10.5625 8 10.5625 Z M 8 10.5625 "/>
		</g>
	</svg>
</template>

<script>
import VTooltip from 'v-tooltip'

export default {
  name: 'HelpIcon',
  props: ['text']
};
</script>

<style lang="css" scoped>
</style>